import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle `
  body {
    font-family: 'IBM Plex Sans';
    background: #001A53;
box-shadow: 0px 10px 28px rgba(0, 0, 0, 0.12);
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1220px;
  }
  @media (min-width: 1600px) {
      .container {
          max-width: 1600px;
          margin: 0 auto;
      }
  }
  @media (min-width: 1900px) {
      .container {
          max-width: 1900px;
          margin: 0 auto;
      }
    }
  section{
    max-width:1920px;
    margin: 0 auto;
  }
  .row{
    margin:0;
  }
  img{
    max-width:100%;
  }
  @media (max-width: 568px){

    h1{
      font-size: 30px;
    }
    h2{
      font-size: 24px;
    }
    p{
      font-size:14px
    }
  }





  







.PrivateSwipeArea-root {
    left: 0 !important;
    width:0 !important;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.swiper-button-next, .swiper-button-prev{
    display:none !important;
}

.shadowlayer{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    opacity: 0.7;
    display:none;
 &.active{
    display:block;
    z-index: 99;
 }

}

.progress-bar{
  background-color:#157ADB;
}
.swal2-styled.swal2-confirm{
  background: linear-gradient(180deg,#2196f3 0%,#1d54b0 100%);
  width:85px;
}
.swal2-styled.swal2-cancel{
  background:linear-gradient(rgb(255, 102, 0) 0%, rgb(255, 81, 0) 100%);
  width:85px;
}










.text-loading {
  font-size: 72px;
  background: linear-gradient(0deg, #1D54B0 -0.05%, #1E6AC6 21.24%, #2082DF 50.7%, #2191EE 77.78%, #2196F3 100.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.center {
  position: relative;
}

.center .center-sub {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}



.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
  font-size: 60px;
}

.waviy span {
  font-family: fangsong;
  position: relative;
  display: inline-block;
  color: #fff;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
}

@keyframes waviy {
  0%,
  40%,
  100% {
      transform: translateY(0)
  }
  20% {
      transform: translateY(-20px)
  }
}
`