import { Container } from './Button.css.jsx'

const Button = ({ children, type = 'default', buttonStyle, ...rest }) => {
    return (
        <Container type={type} style={buttonStyle} {...rest}>
            {children}
        </Container>
    )
}

export default Button
