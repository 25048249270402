import React from 'react'
import Logo from 'assets/img/logo_.png'

function Loadding() {
  
    return (
        <div className="center-sub" style={{ fontFamily: "fantasy",    height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"}}>
        <div className="waviy">
            <span style={{"--i":1}}>
                    <img  src={Logo} style={{width:'70px'}} />  
                  </span>
            <span style={{"--i":2}}>B</span>
            <span style={{"--i":3}}>i</span>
            <span sstyle={{"--i":4}}>r</span>
            <span style={{"--i":5}}>d</span>
            <span  className="text-loading" style={{"--i":6}}>E</span>
            <span  className="text-loading" style={{"--i":7}}>X</span>
        </div>
    </div>
    )
}

export default Loadding
