import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { fixedNumber } from 'components/FormatNumber'
import { ErrorMessage, SuccessMessage } from 'components/Message'
import { PushArray } from 'core/PushArray'
import { DeductUserWallet, PlusWalletUsers } from 'core/WalletUser'
import { formatMoney } from 'core/truncate'
import moment from 'moment'
import { SetUser } from 'utits/auth'

import { HistoryPrivateSale, HistoryStaking } from '../history'
import { GetWallet } from '../wallet'

const initialState = {
    loadding: false,
    getListPrivateSale: []
}

export const handleGetListPrivateSale = (url) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS(url)
            dispatch(GetListPrivateSale(res?.data?.data))
        } catch (error) {
        
        }
    }
}

export const ChangeDataPrivateSale = (id, arrayList, data) => {
    let result = JSON.parse(JSON.stringify(arrayList))
    result.filter((item) => item?._id === id)[0].isBuy = data?.isBuy
    result.filter((item) => item?._id === id)[0].privateSale = data?.privateSale
    result.filter((item) => item?._id === id)[0].createBuy = data?.createBuy
    return result
}

export const handleBuyPrivateSale = (
    data,
    symbol,
    Prices,
    receive,
) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data?._id))

        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/private-sale/buy', data)
            if (getState()?.wallet?.getWallet) {
                const dataWallet = PlusWalletUsers(
                    getState()?.wallet?.getWallet,
                    symbol,
                    Prices
                )
                dispatch(GetWallet(dataWallet))
            }
            const ListPrivateSale = ChangeDataPrivateSale(
                data?._id,
                getState()?.privateSale?.getListPrivateSale,
                res?.data?.data
            )
            ListPrivateSale.forEach((item) => item.isActive = true)
            dispatch(GetListPrivateSale(ListPrivateSale))
            if (getState().history?.historyPrivateSale?.array) {
                const data = {
                    amount: Prices,
                    symbol: symbol,
                    description: `You just bought the Private Sale package and get ${receive} ${symbol}! `,
                    createAt: moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss'),
                    type: 'transfers'
                }
                const pushDataHistory = PushArray(
                    data,
                    getState().history?.historyPrivateSale?.array
                )
                const dataHistory = {
                    array: pushDataHistory?.array,
                    total: getState().history?.historyPrivateSale?.total + 1
                }
                dispatch(HistoryPrivateSale(dataHistory))
            }


            SuccessMessage('Buy Staking Success')
        } catch (error) {
 
            ErrorMessage(error.response.data.message)
        }

        dispatch(Loadding(false))
    }
}
export const ChangeReceivePrivateSale = (id, arrayList, month) => {
    let result = JSON.parse(JSON.stringify(arrayList))
    let test = arrayList.filter((item) => item?._id === id)[0].privateSale
    let test2 = JSON.parse(JSON.stringify(test))
    test2.filter((item) => item.month == month)[0].isReceive = true
    result.filter((item) => item?._id === id)[0].privateSale = test2
    // let test100 = 0
    // test?.map((e) => {
    //     if (e.isReceive == true) {
    //         test100 = test100 + 1
    //         return test100
    //     }
    // })
    // if (test100 == 4) {
    //     result.filter((item) => item?._id === id)[0].privateSale = 0
    //     result.filter((item) => item?._id === id)[0].isBuy = false
    // }

    return result
}
export const handleReceive = (data, amount) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/private-sale/receive', data)
            if (getState()?.wallet?.getWallet) {
                const dataWallet = DeductUserWallet(
                    getState()?.wallet?.getWallet,
                    'BRX',
                    amount
                )
                dispatch(GetWallet(dataWallet))
            }
            const listStaking = ChangeReceivePrivateSale(
                data?._id,
                getState()?.privateSale?.getListPrivateSale,
                data?.month
            )
            dispatch(GetListPrivateSale(listStaking))
            if (getState().history?.historyPrivateSale?.array) {
                const data = {
                    amount: amount,
                    symbol: 'BRX',
                    description: `You just received ${amount} BRX from private sale !`,
                    createAt: moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss'),
                    type: 'receive'
                }
                const pushDataHistory = PushArray(
                    data,
                    getState().history?.historyPrivateSale?.array
                )
                const dataHistory = {
                    array: pushDataHistory?.array,
                    total: getState().history?.historyPrivateSale?.total + 1
                }
                dispatch(HistoryPrivateSale(dataHistory))
            }
            SuccessMessage('Buy Staking Success')
        } catch (error) {
            console.log(error)
            // ErrorMessage(error.response.data.message)
        }

        dispatch(Loadding(false))
    }
}
const privateSaleSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },
        GetListPrivateSale: (state, action) => {
            return {
                ...state,
                getListPrivateSale: action.payload
            }
        }
    }
})

const { reducer, actions } = privateSaleSlice
export const { Loadding, GetListPrivateSale } = actions
export default reducer
