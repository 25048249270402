import { HomeRouter } from 'layout/HomeRouter'
import pMinDelay from 'p-min-delay'
import Maintenance from 'page/Maintenance'
import React from 'react'

import PrivateRoute from './layout/PrivateRoute'
const Rewarded = React.lazy(() => pMinDelay(import('./page/Rewarded'), 500))

const Staking = React.lazy(() => pMinDelay(import('./page/Staking'), 500))
const Game = React.lazy(() => pMinDelay(import('./page/Game'), 500))

const PrivateSale = React.lazy(() => pMinDelay(import('./page/PrivateSale'), 500))

const Home = React.lazy(() => pMinDelay(import('./page/home'), 500))

const ForgotPassword = React.lazy(() => pMinDelay(import('./page/auth/ForgotPass'), 500))

const Login = React.lazy(() => pMinDelay(import('./page/auth/Login'), 500))
const Wallet = React.lazy(() => pMinDelay(import('./page/Wallet'), 500))
const Profile = React.lazy(() => pMinDelay(import('./page/Profile'), 500))
const Claim = React.lazy(() => pMinDelay(import('./page/Exchange'), 500))
const History = React.lazy(() => pMinDelay(import('./page/History'), 500))
const Referral = React.lazy(() => pMinDelay(import('./page/Referral'), 500))
const Farming = React.lazy(() => pMinDelay(import('./page/Farming'), 500))
const Invest = React.lazy(() => pMinDelay(import('./page/Invest'), 500))





const Page404 = React.lazy(() => pMinDelay(import('./page/NotFound'), 500))

const _Page404 = (
    <HomeRouter
        Element={Page404}
        meta={{
            title: 'Not Found | Bridex'
        }}
    />
)
const _Home = (
    <HomeRouter
        Element={Home}
        meta={{
            title: 'Bridex'
        }}
    />
)
const _Maintenance = (
    <HomeRouter
        Element={Maintenance}
        meta={{
            title: 'Maintenance | Bridex'
        }}
    />
)
const _Referral = (
    <HomeRouter
        Element={Referral}
        meta={{
            title: 'Referral | Bridex'
        }}
    />
)
const _Profile = (
    <HomeRouter
        Element={Profile}
        meta={{
            title: 'Profile | Bridex'
        }}
    />
)


const _History = (
    <HomeRouter
        Element={History}
        meta={{
            title: 'History | Bridex'
        }}
    />
)

const _Deposit = (
    <HomeRouter
        Element={Wallet}
        meta={{
            title: 'Deposit | Bridex'
        }}
    />
)

const _Withdraw = (
    <HomeRouter
        Element={Wallet}
        meta={{
            title: 'Withdraw | Bridex'
        }}
    />
)
const _Transfer = (
    <HomeRouter
        Element={Wallet}
        meta={{
            title: 'Transfer | Bridex'
        }}
    />
)



const _ForgotPassword = (
    <HomeRouter
        Element={ForgotPassword}
        meta={{
            title: 'Forgot Password | Bridex'
        }}
    />
)


const _ChangePassword = (
    <HomeRouter
        Element={Profile}
        meta={{
            title: 'Change Password | Bridex'
        }}
    />
)


const _PrivateSale = (
    <HomeRouter
        Element={PrivateSale}
        meta={{
            title: 'PrivateSale | Bridex'
        }}
    />
)

const _Login = (
    <HomeRouter
        Element={Login}
        meta={{
            title: 'Login | Bridex'
        }}
    />
)

const _Invest = (
    <HomeRouter
        Element={Invest}
        meta={{
            title: 'Investment Trust | Bridex'
        }}
    />
)









const _Claim = (
    <HomeRouter
        Element={Claim}
        meta={{
            title: 'Exchange | Bridex'
        }}
    />
)

const _Rewarded = (
    <HomeRouter
        Element={Rewarded}
        meta={{
            title: 'Reward | Bridex'
        }}
    />
)
const _Game = (
    <HomeRouter
        Element={Game}
        meta={{
            title: 'Exchange | Bridex'
        }}
    />
)
const _Staking = (
    <HomeRouter
        Element={Staking}
        meta={{
            title: 'Staking | Bridex'
        }}
    />
)

const _Farming = (
    <HomeRouter
        Element={Farming}
        meta={{
            title: 'Farming | Bridex'
        }}
    />
)

const routers = [
    {
        path: '/',
        element: <PrivateRoute />,
        children: [
            {
                path: '/',
                element: _Home
            },
          
          
            {
                path: '/deposit',
                element: _Deposit
            },
           
          
            {
                path: '/withdraw',
                element: _Withdraw
            },
            {
                path: '/invest',
                element: _Invest
            },
            {
                path: '/transfer',
                element: _Transfer
            },
            {
                path: '/farming',
                element: _Farming
            },
            {
                path: '/kyc',
                element: _Profile
            },
            {
                path: '/profile',
                element: _Profile
            },
            {
                path: '/change-password',
                element: _ChangePassword
            },
            {
                path: '/staking',
                element: _Staking
            },
            {
                path: '/private-sale',
                element: _PrivateSale
            },
     
            {
                path: '/play-game',
                element: _Game
            },
            {
                path: '/reward',
                element: _Rewarded
            },
         
         
            {
                path: '/exchange',
                element: _Claim
            },
         
            {
                path: '/history-staking',
                element: _History
            },
            {
                path: '/history-private-sale',
                element: _History
            },
            {
                path: '/referral',
                element: _Referral
            },
            {
                path: '/register?type=ref',
                element: _Home
            },
            {
                path: '/register',
                element: _Home
            },
          
            {
                path: '/login:slug',
                element: _Home
            },
            {
                path: '/login',
                element: _Home
            },
        ]
    },
 
 

    {
        path: '/forgot-password',
        element: _ForgotPassword
    },
    
    {
        path: '/maintenance',
        element: _Maintenance
    },


    {
        path: '*',
        element: _Page404
    }
]

export default routers
