import { port } from 'port';
import { io } from 'socket.io-client'




// const port = 'https://socketrealfootball.herokuapp.com'


const socket = io(port, {
    transports: ['websocket', 'polling', 'flashsocket']
});
export default socket