import { DataPriceCoin } from 'core/Coin'
import React from 'react'
import { useSelector } from 'react-redux'
import bar from 'assets/img/chart.png'

export default function PriceBRX() {
    const { getPrice } = useSelector((state) => state.socket)
    return (
        <div style={{ color: 'white', fontSize:'14px' }}>
            <strong> 1 BRX </strong>
            <span>= {getPrice ? <>{DataPriceCoin(getPrice, 'BRX')?.price}</> : "0.05"}  USD 
      
            </span>
            <a href='#' style={{marginLeft:'5px'}}>
            <img src={bar} alt="" />
            </a>
        </div>
    )
}
