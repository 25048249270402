import * as yup from 'yup'

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

//login
export const LOGIN_FORM_FIELD = {
    EMAIL: 'email',
    PASSWORD: 'password'
}

export const schemeLogin = yup.object().shape({
    [LOGIN_FORM_FIELD.PASSWORD]: yup
        .string()
        .min(3, 'Minimum character 3')
        .max(40, 'Maximum character 40')
        .required('This field cannot be left blank'),
    [LOGIN_FORM_FIELD.EMAIL]: yup
        .string()
        .matches(emailReg, 'Invalid email')
        .max(100, 'Maximum character 100')
        .required('This field cannot be left blank')
})

// register
export const REGISTER_FORM_FIELD = {
    // USERNAME: 'userName',
    // DISPLAY_NAME: 'displayName',
    EMAIL: 'email',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirmPassword',
    COUNTRY: 'countryID',
    REFERRAL_ID: 'referralID',
    SEND_OTP: 'otpEmail'
}

export const schemeRegister = yup.object().shape({
    // [REGISTER_FORM_FIELD.USERNAME]: yup
    //     .string()
    //     .min(3, 'Minimum character 3')
    //     .max(40, 'Maximum character 40')
    //     .required('Username cannot be left blank'),
    // [REGISTER_FORM_FIELD.DISPLAY_NAME]: yup
    //     .string()
    //     .min(3, 'Minimum character 3')
    //     .max(40, 'Maximum character 40')
    //     .required('Display name cannot be left blank'),
    [REGISTER_FORM_FIELD.EMAIL]: yup
        .string()
        .matches(emailReg, 'Invalid email')
        .max(100, 'Maximum character 100')
        .required('Email cannot be left blank'),
    // [REGISTER_FORM_FIELD.COUNTRY]: yup.mixed().required().label('Selected Country'),
    [REGISTER_FORM_FIELD.PASSWORD]: yup
        .string()
        .max(20, 'Maximum character 20')
        .required('Password cannot be left blank')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    // [REGISTER_FORM_FIELD.CONFIRM_PASSWORD]: yup
    //     .string()
    //     .required('Confirm Password cannot be left blank')
    //     .oneOf([yup.ref('password'), null], 'Passwords must match'),
    [REGISTER_FORM_FIELD.SEND_OTP]: yup.string().max(10, 'Maximum character 10')
        // [REGISTER_FORM_FIELD.REFERRAL_ID]: yup.string().max(10, 'Maximum character 10')
})

// forgot password
export const FORGOT_PASSWORD_FORM_FIELD = {
    EMAIL: 'email',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
    SEND_OTP: 'otpEmail'
}

export const schemeForgotPassword = yup.object().shape({
    [FORGOT_PASSWORD_FORM_FIELD.EMAIL]: yup
        .string()
        .matches(emailReg, 'Invalid email')
        .max(100, 'Maximum character 100')
        .required('Email cannot be left blank'),
    [FORGOT_PASSWORD_FORM_FIELD.NEW_PASSWORD]: yup
        .string()
        .max(20, 'Maximum character 20')
        .required('New Password cannot be left blank')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    [FORGOT_PASSWORD_FORM_FIELD.CONFIRM_PASSWORD]: yup
        .string()
        .required('Confirm Password cannot be left blank')
        .oneOf([yup.ref('newPassword'), null], 'Password must match'),
    [FORGOT_PASSWORD_FORM_FIELD.SEND_OTP]: yup.string().max(10, 'Maximum character 10')
})

// change password
export const CHANGE_PASSWORD_FORM_FIELD = {
    OLD_PASSWORD: 'oldPassword',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword'
}

export const schemeChangePassword = yup.object().shape({
    [CHANGE_PASSWORD_FORM_FIELD.OLD_PASSWORD]: yup
        .string()
        .max(20, 'Maximum character 20')
        .required('OldPassword cannot be left blank'),
    [CHANGE_PASSWORD_FORM_FIELD.NEW_PASSWORD]: yup
        .string()
        .max(20, 'Maximum character 20')
        .required('New Password cannot be left blank')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    [CHANGE_PASSWORD_FORM_FIELD.CONFIRM_PASSWORD]: yup
        .string()
        .required('Confirm Password cannot be left blank')
        .oneOf([yup.ref('newPassword'), null], 'Password must match')
})

// wallet withdraw
export const WITHDRAW_FORM_FIELD = {
    WALLET_ADDRESS: 'addressTo',
    AMOUNT: 'amount'
}

export const schemeWithdraw = yup.object().shape({
    [WITHDRAW_FORM_FIELD.WALLET_ADDRESS]: yup
        .string()
        .max(100, 'Maximum character 100')
        .required('Wallet Address cannot be left blank'),
    [WITHDRAW_FORM_FIELD.AMOUNT]: yup
        .string()
        .max(1000, 'Maximum character 1000')
        .required('Amount cannot be left blank')
        .matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, {
            message: 'Inccorect format. Example 25 or 25.1',
            excludeEmptyString: true
        })
})

// wallet tranfer
export const TRANSFER_FORM_FIELD = {
    WALLET_USERNAME: 'userName',
    AMOUNT: 'amount'
}

export const schemeTransfer = yup.object().shape({
    [TRANSFER_FORM_FIELD.WALLET_USERNAME]: yup
        .string()
        .max(100, 'Maximum character 100')
        .required('Wallet Username cannot be left blank'),
    [TRANSFER_FORM_FIELD.AMOUNT]: yup
        .string()
        .max(1000, 'Maximum character 1000')
        .required('Amount cannot be left blank')
        .matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, {
            message: 'Inccorect format. Example 25 or 25.1',
            excludeEmptyString: true
        })
})

// KYC Step 1
export const KYC_STEP1_FORM_FIELD = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PASSPORT: 'documentCode',
    COUNTRY: 'countryId',
    DOCUMENT: 'documentId',
    CHECKBOX: 'chooseCb',
    PHOTO: 'photo'
}

export const schemeKycStep1 = yup.object().shape({
    [KYC_STEP1_FORM_FIELD.FIRST_NAME]: yup
        .string()
        .min(2, 'Minimum character 2')
        .max(40, 'Maximum character 40')
        .required('Firstname cannot be left blank'),
    [KYC_STEP1_FORM_FIELD.LAST_NAME]: yup
        .string()
        .min(2, 'Minimum character 2')
        .max(40, 'Maximum character 40')
        .required('Lastname cannot be left blank'),
    [KYC_STEP1_FORM_FIELD.DOCUMENT]: yup.mixed().required().label('Selected Document'),

    [KYC_STEP1_FORM_FIELD.PASSPORT]: yup
        .string()
        .min(2, 'Minimum character 2')
        .required('Corresponding document number cannot be left blank'),
    [KYC_STEP1_FORM_FIELD.COUNTRY]: yup.mixed().required().label('Selected Country'),
    [KYC_STEP1_FORM_FIELD.CHECKBOX]: yup
        .bool()
        .oneOf([true], 'Checkbox selection is required')
})

export const CLAIM_FROM_FIELD = {
    CLAIM: 'claim'
}

export const schemeClaim = yup.object().shape({
    [CLAIM_FROM_FIELD.Claim]: yup.string().required('Amount cannot be left blank')
})

//sell

export const SELL_FROM_FIELDS = {
    AMOUNT: 'Amount'
}
export const schemeModalSell = yup.object().shape({
    [SELL_FROM_FIELDS.AMOUNT]: yup
        .string()
        .required('Amount Sell cannot be left blank')
        .matches(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/gm, "Mumber must be more than 0")
})

//profile

export const PROFILE_FORM_FIELD = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COUNTRY: 'countryId',
    PHOTO: 'photo',
    USERNAME: 'userName',
    DISPLAY_NAME: 'displayName',
    EMAIL: 'email',
    PHONE: 'phone'
}

export const schemeProfile = yup.object().shape({
    [PROFILE_FORM_FIELD.FIRST_NAME]: yup.string().max(40, 'Maximum character 40'),
    [PROFILE_FORM_FIELD.LAST_NAME]: yup.string().max(40, 'Maximum character 40'),
    // [PROFILE_FORM_FIELD.USERNAME]: yup.string().max(40, 'Maximum character 40'),
    [PROFILE_FORM_FIELD.DISPLAY_NAME]: yup.string().max(40, 'Maximum character 40'),
    // [PROFILE_FORM_FIELD.EMAIL]: yup
    //     .string()
    //     .matches(emailReg, 'Invalid email')
    //     .max(100, 'Maximum character 100')
    //     .required('Email cannot be left blank'),
    [PROFILE_FORM_FIELD.PHONE]: yup.string().max(20, 'Maximum character 20')
        // [PROFILE_FORM_FIELD.COUNTRY]: yup.mixed().required().label('Selected Country')
})