import { createSlice } from "@reduxjs/toolkit";
import BUSD from 'assets/img/coin/BUSD.png'


const initialState = {
  isMobile: false,
  openMenu: true,
  isOverlay:false,
  coinPayment: JSON.parse(localStorage.getItem('payments'))||{
    symbol:"BUSD",
    image:BUSD,
   price:0,
  } ,
  loginResgister:''
};

export const actionChangeDevice = payload => {
  return async (dispatch, getState) => {
    if (payload !== getState().global.isMobile) dispatch(changeDevice(payload));
  };
};

export const actionOpenMenu = payload => {
  return async (dispatch, getState) => {
    if (!getState().global.isMobile) dispatch(OpenMenu(payload));
  };
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeDevice: (state, action) => {
      return {
        ...state,
        isMobile: action.payload,
      };
    },
    LoginResgister: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        loginResgister: action.payload,
      };
    },
    OpenMenu: (state, action) => {
      return {
        ...state,
        openMenu: action.payload,
      };
    },
    OpenOverlay: (state, action) => {
      return {
        ...state,
        isOverlay: action.payload,
      };
    },
    CoinPayment: (state, action) => {
      const data =  {
        symbol: action.payload.symbol,  image:action.payload.image,
        price:action.payload.price
      }
      localStorage.setItem("payments", JSON.stringify(data))
    
      return {
        ...state,
        coinPayment: action.payload,
      };
    },
  },
});

const { reducer, actions } = globalSlice;
export const { changeDevice, OpenMenu ,OpenOverlay,CoinPayment,LoginResgister} = actions;
export default reducer;
