import NotificationsIcon from '@mui/icons-material/Notifications'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { DeductUserWallet, SetDeductUserWallet } from 'core/WalletUser'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetEmit, GetNotify, IconNotify } from 'redux/slices/socket'
import { handleGetWallet, handleHistoryWithdraw } from 'redux/slices/wallet'
import socket from 'socket'
import { SetUser, getSession } from 'utits/auth'

import Reward from './Reward'
import System from './System'
import { MenuNotify, MenuNotifyItem } from './styles'
import BSON from 'bson'

export default function Notify() {
    const { getUserStore } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const { notify, iconNotify, emit } = useSelector((state) => state.socket)
    const [limit, setLimit] = React.useState(5)
    const [loading, setLoading] = React.useState(false)
    const [state, setState] = React.useState({
        right: false
    })
    const [active, setActive] = React.useState('System')
    const token = getSession()
    React.useEffect(() => {
     socket.emit('send-notification', token)
        socket.on('notification', (result) => {
            const res = BSON.deserialize(result)
            if (notify?.total < res.total) {
                dispatch(GetEmit(true))
                dispatch(IconNotify(true))
                dispatch(
                    GetNotify({
                        data: res?.data,
                        total: res?.total
                    })
                )
                if (res?.data[0]?.name?.split(' ')[0] == 'Deposit') {
                    dispatch(handleGetWallet())
                } else if (res?.data[0]?.name?.split(' ')[0] == 'Withdrawal') {
                    if (res?.data[0]?.name?.split(' ')[2] == 'failed') {
                        dispatch(handleGetWallet())
                    }
                    dispatch(handleHistoryWithdraw(1))
                } else if (res?.data[0]?.name === 'Refusing to kyc !') {
                    const data = JSON.parse(JSON.stringify(getUserStore))
                    data.kyc1.status = "rejected"
                    SetUser(data, dispatch)
                }
                else if (res?.data[0]?.name === 'Browse Kyc successfully !') {
                    const data =JSON.parse(JSON.stringify(getUserStore))
                    data.kyc1.status = "approved"
                    SetUser(data, dispatch)
                }
                else if (res?.data[0]?.name === 'Receive BUSD') {
                    dispatch(handleGetWallet())
                }
            } else {
                dispatch(GetEmit(true))
                dispatch(
                    GetNotify({
                        data: res?.data,
                        total: res?.total
                    })
                )
            }
        })
    }, [limit,token])
    const OnClick = () => {
        dispatch(IconNotify(false))
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300,
                height: '100%',
                bgcolor: '#000d29'
            }}
            role='presentation'>
            <MenuNotify>
                <MenuNotifyItem
                    active={active == 'System'}
                    onClick={() => setActive('System')}>
                    System Notice
                </MenuNotifyItem>
                <MenuNotifyItem
                    active={active == 'Reward'}
                    onClick={() => setActive('Reward')}>
                    Reward
                </MenuNotifyItem>
            </MenuNotify>

            {active == 'System' && (
                <>
                    {!notify?.data ? (
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            <i
                                className='fa fa-refresh fa-spin'
                                style={{ fontSize: '20px', color: 'red' }}></i>
                        </div>
                    ) : (
                        <System
                            limit={limit}
                            setLimit={setLimit}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )}
                </>
            )}
            {active == 'Reward' && (
                <>
                    <Reward />
                </>
            )}
        </Box>
    )

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div
                        onClick={toggleDrawer(anchor, true)}
                        style={{
                        
                            cursor: 'pointer',
                            position: 'relative'
                        }}>
                        {iconNotify ? (
                            <div>
                                <NotificationsIcon onClick={() => OnClick()} />
                                <span
                                    style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        right: '12px',
                                        top: '-10px',
                                        fontWeight: '700',
                                        color: 'white',
                                        background: 'red',
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '50%',
                                        fontSize: '10px'
                                    }}>
                                    !
                                </span>
                            </div>
                        ) : (
                            <NotificationsIcon />
                        )}
                    </div>
                    {anchor && 
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)} style={{width:0}}>
                        {list(anchor)}
                    </SwipeableDrawer>}
                </React.Fragment>
            ))}
        </div>
    )
}
