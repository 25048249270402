import { combineReducers } from 'redux'

import referral from './Referral'
import auth from './auth'
import common from './common'
import exchange from './exchange'
import global from './global'
import history from './history'
import recaptchaF2A from './recaptchaF2A'
import socket from './socket'
import user from './user'
import wallet from './wallet'
import staking from './staking'
import game from './game'
import farming from './farming'
import privateSale from './privateSale'
import reward from './reward'




const reducers = combineReducers({
    common,
    auth,
    exchange,
    recaptchaF2A,
    wallet,
    referral,
    global,
    user,
    history,
    socket,
    staking,
    game,
    farming,
    privateSale,
    reward
})
export default (state, action) =>
reducers(action.type === 'REVERT_ALL' ? undefined : state, action)