import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import BUSD from 'assets/img/coin/BUSD.png'
import BSON from 'bson'
import { ErrorMessage } from 'components/Message'
import { DeductUserWallet, PlusWalletUsers } from 'core/WalletUser'
import socket from 'socket'

import { GetWallet } from '../wallet'

const initialState = {
    listPay: [0],
    listRank:[],
    loading:false
}

export const handleGetListPay = (data) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS('/api/user/v1/game/get', data, true)
            dispatch(ListPay(res?.data?.data))
        } catch (error) {
            console.log(error)
        }
    }
}
export const handleGetListRank = (data) => {
    return async (dispatch) => {
        dispatch(Loading(true))
        try {
            let res = await CreateApi.CreateApiSSS('/api/user/v1/game/my-bet', true)
            dispatch(ListRank(res?.data?.data))
        } catch (error) {
            console.log(error)
        }
        dispatch(Loading(false))
    }
}

export const handleSubmitBet = (setActive, data, setDis, setAudio) => {
    return async (dispatch, getState) => {
        socket.off('bet')
        socket.off('send-bet')
        socket.on('bet', (result) => {
            const res = BSON.deserialize(result)
         
            if (!res?.err) {
                if (res?.data?.isWin) {
                    const dataWallet = DeductUserWallet(
                        getState()?.wallet?.getWallet,
                        data?.symbol,
                        res?.data?.transaction?.amount
                    )
                    setTimeout(() => {
                        setAudio(true)
                    }, 500)
                    dispatch(GetWallet(dataWallet))
                } else {
                    const dataWallet = PlusWalletUsers(
                        getState()?.wallet?.getWallet,
                        data.symbol,
                        data?.amount
                    )

                    dispatch(GetWallet(dataWallet))
                }
                const newDataListRank = JSON.parse(JSON.stringify(getState().game.listRank))
                if(newDataListRank?.length >= 30){
                    newDataListRank.pop()
                }
                newDataListRank.unshift(res?.data)
                dispatch(ListRank(newDataListRank))
                setActive(true)
                const datas = JSON.parse(JSON.stringify(getState()?.game?.listPay))
                if (getState()?.game?.listPay?.length >= 6) {
                    datas.shift()
                    datas.push(res?.data)
                    dispatch(ListPay(datas))
                } else {
                    datas.push(res?.data)
                    dispatch(ListPay(datas))
                }
            } else {
                ErrorMessage(res?.message)
            }
            setDis(false)
        })
    }
}

export const handleSubmitAuto = (setActive, data, setAudio, form, setForm, timeout,setDis) => {
    return async (dispatch, getState) => {
        socket.off('send-bet')
        socket.off('bet')

        socket.on('bet', (result) => {
            const res = BSON.deserialize(result)
            if (!res?.err) {
                if (res?.data?.isWin) {
                    const dataWallet = DeductUserWallet(
                        getState()?.wallet?.getWallet,
                        data?.symbol,
                        res?.data?.transaction?.amount
                    )
                    setTimeout(() => {
                        setAudio(true)
                    }, 300)
                    form.bonus = form.bonus + Number(res?.data?.transaction?.amount)
                    dispatch(GetWallet(dataWallet))
                } else {
                    const dataWallet = PlusWalletUsers(
                        getState()?.wallet?.getWallet,
                        data.symbol,
                        data?.amount
                    )

                    form.lose = form.lose + data?.amount
                    dispatch(GetWallet(dataWallet))
                }
                if (form.timeBet > 0) {
                    form.timeBet = form.timeBet - 1
                    if (form.timeBet == 0) {
                        clearInterval(timeout)
                        socket.off('send-bet')
                        socket.off('bet')
                        setDis(false)
                    }
                }
                const newDataListRank = JSON.parse(JSON.stringify(getState().game.listRank))
                if(newDataListRank?.length >= 30){
                    newDataListRank.pop()
                }
                newDataListRank.unshift(res?.data)
                dispatch(ListRank(newDataListRank))
                setActive(true)
                const datas = JSON.parse(JSON.stringify(getState()?.game?.listPay))
                if (getState()?.game?.listPay?.length >= 6) {
                    datas.shift()
                    datas.push(res?.data)
                    dispatch(ListPay(datas))
                } else {
                    datas.push(res?.data)
                    dispatch(ListPay(datas))
                }
                setForm({ ...form })
            } else {
                ErrorMessage(res?.message)
                setDis(false)
                clearInterval(timeout)
                socket.off('send-bet')
                socket.off('bet')
            }
        })
    }
}

const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        Loading: (state, action) => {
            return {
                ...state,
                loading: action.payload
            }
        },
        ListPay: (state, action) => {
            return {
                ...state,
                listPay: action.payload
            }
        },
        ListRank: (state, action) => {
            return {
                ...state,
                listRank: action.payload
            }
        }
    }
})

const { reducer, actions } = GameSlice
export const { ListPay ,ListRank,Loading} = actions
export default reducer
