import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'

const initialState = {
    loadding: false,
    notify: JSON.parse(localStorage.getItem('setNoti'))||[],
    emit:false,
    energy: [],
    iconNotify: JSON.parse(localStorage.getItem('icons')) || false,
    getPrice: JSON.parse(localStorage.getItem('PP'))|| [],
    getReward:[],
    amountReward:0,
}
export const handleGetPrice = (setActive) => {
    return async (dispatch) => {

        try {
            let res = await CreateApi.CreateApiGET(
                '/api/public/v1/coin',
            )
            dispatch(GetPrice(res?.data?.data))
            setActive(true)
        } catch (error) {}

    }
}
const nftSlice = createSlice({
    name: 'nft',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },
        GetEmit: (state, action) => {
            return {
                ...state,
                emit: action.payload
            }
        },
        GetPrice: (state, action) => {
            localStorage.setItem('PP', JSON.stringify(action.payload))
            return {
                ...state,
                getPrice: action.payload
            }
        },
        GetNotify: (state, action) => {
            localStorage.setItem('setNoti', JSON.stringify(action.payload))
            return {
                ...state,
                notify: action.payload
            }
        },
        IconNotify: (state, action) => {
            localStorage.setItem('icons', JSON.stringify(action.payload))
            return {
                ...state,
                iconNotify: action.payload
            }
        },
        GetReward: (state, action) => {
            return {
                ...state,
                getReward: action.payload
            }
        },
        AmountReward: (state, action) => {
            return {
                ...state,
                amountReward: action.payload
            }
        },
    }
})

const { reducer, actions } = nftSlice
export const { Loadding, GetNotify, GetEnergy, IconNotify ,GetEmit,GetPrice,GetReward,AmountReward} = actions
export default reducer
