import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { OpenOverlay } from 'redux/slices/global';



function ShadowLayer() {
    const dispatch = useDispatch()
 const {isOverlay} = useSelector(state => state.global)

    useEffect(() => {
        let html = document.querySelector("html")
        let shadowLayer = document.querySelector(".shadowlayer")

        if (isOverlay) {
            shadowLayer.classList.add("active")
            html.style.overflow = "hidden"
        } else {
            setTimeout(() => shadowLayer.classList.remove("active"), 300)
            html.style.overflow = "auto"
        }
    }, [isOverlay])

    return (
        <>
        {isOverlay && 
            <div
                className={`shadowlayer`}
                onClick={() => dispatch(OpenOverlay(!isOverlay))}
            >
                <div className={`shadowlayer-block ${isOverlay && "active"}`} />
            </div>}
        </>
    );
}

export default ShadowLayer