import { themes } from 'common/styles/theme'
import styled from 'styled-components'

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
`

export const Wrapper = {

    main: styled.form`
        background: white;
        box-shadow: 0px 10px 28px rgb(0 0 0 / 12%);
        border-radius: 20px;
        width: 95%;
        max-width: 494px;
        z-index: 999;
        margin: 90px auto;
        position: relative;
        position:fixed;
        top:30%;
        left:50%;
        transform:translate(-50%, -50%);

    `,
    title: styled.div`
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0px 0px;
        color: ${themes.colors.black};
        > img {
            margin-bottom: 20px;
            width: 100%;
            max-width:190px;
        }
        > h2 {
            text-align: center;
            width: 100%;
            color: ${themes.colors.black};
            font-weight: bold;
            font-size: 30px;
            margin:0;
        }
    `,
    context: styled.div`
        padding: 0 20px 0 20px;
        @media (max-width: 768px) {
            padding: 5px 2px 20px 2px;
        }
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin: 0 auto;
        > button {
        }
    `,
    inputWrapper: styled.div`
        ${themes.mixin.flexDirectionNotCenter()};
        display: flex;
        flex-direction: column;
        gap: 5px;

        @media (max-width: 500px) {
            font-size: 13px;
        }
        color: ${themes.colors.black};
        > p {
            margin-bottom: 0px;
            font-size: 16px;
            @media (max-width: 500px) {
                font-size: 13px;
            }
        }
        > .message-error {
            @media (max-width: 500px) {
                font-size: 10px;
            }
            font-size: 13px;
            color: red;
        }
    `,
    input: styled.input`
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7px;
        color: ${themes.colors.black};
        padding-left: 15px;
        border: 1px solid #EAEAEA;
        outline: none;
        :autofill {
            outline: none;
            box-shadow: 0px 0px 2px red;
            background-color: red;
        }
    `,
    link: styled.div`
        ${themes.mixin.flexDirectionNotCenter()};
        gap: 5px;
        > a {
            > p {
                margin-bottom: 0px;
            }
        }
        > p {
            color: ${themes.colors.black};
        }
        color: ${themes.colors.black};
        font-size: 16px;
        font-weight: 500;
        @media (max-width: 500px) {
            font-size: 11px;
        }
    `
}
