import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import {  MessageToastError } from 'components/Message'
import SVG from 'components/SVG'
import { REGISTER_FORM_FIELD, schemeRegister } from 'core/customValidate'
import { port, portRegister } from 'port'
import React, { useEffect, useState } from 'react'
import {  useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link,  useLocation,  useNavigate, useParams } from 'react-router-dom'
import {
    HandleSubmitSendOtp,
    HandleSubmitSignUp,
    handleGetCountry
} from 'redux/slices/auth'
import { LoginResgister } from 'redux/slices/global'

import { Wrapper } from './styles'

const RegisterUser = () => {
  const location = useLocation()
    const searchParams = new URLSearchParams(location?.search)
  const slug = searchParams.get('type')
    const dispatch = useDispatch()
    const [checkBox, setCheckBox] = useState(false)
    const [counter, setCounter] = useState(0)
    const { getLoading } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schemeRegister)
    })

    useEffect(() => {
        dispatch(handleGetCountry())
    }, [])

    useEffect(() => {
        localStorage.setItem('timeout', counter)
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
        return () => clearInterval(timer)
    }, [counter])

    const handleCheckBox = (event) => {
        const { checked } = event.target
        setCheckBox(checked)
    }
    const submit = (dataForm) => {
        if (checkBox === true) {
            const inputValue = { ...dataForm, isSendMail: false }
            dispatch(HandleSubmitSignUp(inputValue, navigate))
        } else {
            MessageToastError(
                'You do not agree to our Terms of Service and Privacy Policy'
            )
        }
    }
    const submitSendOtp = (dataForm) => {
        if (Object.keys(errors).length > 0) return
        const inputValue = {
            ...dataForm,
            isSendMail: true,
            otpEmail: '0000'
        }
        dispatch(HandleSubmitSendOtp(inputValue))
        setCounter(60)
    }

    const hadleLogin = () => {
        dispatch(LoginResgister('login'))
     
    }

    return (
        <>
            <Wrapper.content onSubmit={handleSubmit(submit)}>
                <Wrapper.inputWrapper>
                    <Wrapper.input
                        type='text'
                        placeholder='Email'
                        error={errors[REGISTER_FORM_FIELD.EMAIL] === undefined}
                        {...register('email', {
                            required: true
                        })}
                    />
                    <p className='message-error'>
                        {errors[REGISTER_FORM_FIELD.EMAIL]?.message}
                    </p>
                </Wrapper.inputWrapper>
          
                <Wrapper.inputWrapper>
                    <Wrapper.input
                        type='password'
                        placeholder='Password'
                        error={errors[REGISTER_FORM_FIELD.PASSWORD] === undefined}
                        {...register('password', {
                            required: true
                        })}
                    />
                    <p className='message-error'>
                        {errors[REGISTER_FORM_FIELD.PASSWORD]?.message}
                    </p>
                </Wrapper.inputWrapper>
                {/* <Wrapper.inputWrapper>
                    <p>Confirm Password</p>
                    <Wrapper.input
                        type='password'
                        placeholder='Confirm Password'
                        error={errors[REGISTER_FORM_FIELD.CONFIRM_PASSWORD] === undefined}
                        {...register('confirmPassword', {
                            required: true
                        })}
                    />
                    <p className='message-error'>
                        {errors[REGISTER_FORM_FIELD.CONFIRM_PASSWORD]?.message}
                    </p>
                </Wrapper.inputWrapper> */}
                {/* <Wrapper.inputWrapper>
                    <p>Country</p>
                    {Country(control, 'countryID')}
                    <p className='message-error'>
                        {errors[REGISTER_FORM_FIELD.COUNTRY]?.message}
                    </p>
                </Wrapper.inputWrapper> */}
                <Wrapper.inputWrapper>
                    <Wrapper.input
                        type='text'
                        placeholder='Referral'
                        value={slug && slug }
                        {...register('ref')}
                    />
                </Wrapper.inputWrapper>
                <Wrapper.inputWrapper>
                    <Wrapper.inputOtp>
                        <Wrapper.input
                            type='number'
                            placeholder='OTP Email'
                            error={errors[REGISTER_FORM_FIELD.SEND_OTP] === undefined}
                            {...register('otpEmail', {
                                required: true
                            })}
                        />
                        {counter !== 0 ? (
                            <div className='disabled opacity' style={{color:'white'}}>{counter}s</div>
                        ) : (
                            <div
                                onClick={handleSubmit(submitSendOtp)}
                                className='cursor-pointer' style={{color:'white'}}>
                                    Send OTP
                            </div>
                        )}
                    </Wrapper.inputOtp>
                    <p className='message-error'>
                        {errors[REGISTER_FORM_FIELD.SEND_OTP]?.message}
                    </p>
                </Wrapper.inputWrapper>
                {/* <Recaptcha /> */}
                <Wrapper.checkbox>
                    <input type='checkbox' name='ref' onChange={handleCheckBox} />
                    <span>
                        You agree with <Link to=''>Terms of Service</Link> and{' '}
                        <Link to=''>Privacy Policy</Link> by Real Football
                    </span>
                </Wrapper.checkbox>

                <Button type='submit'>
                    {' '}
                    {getLoading ? <i className='fa fa-refresh fa-spin'></i> : 'Sign Up'}
                </Button>
                <Wrapper.link>
                    <Wrapper.connect>
                        <span>Or Sign Up with</span>
                        <a href={`${port}/auth/google`}>
                            <SVG name='logo_google' />
                        </a>
                    </Wrapper.connect>
                    <Wrapper.loginNow>
                        Do you already have an account?
                        <div to='/login'  onClick={() =>hadleLogin()}>
                            <b>LOG IN NOW </b>
                        </div>
                    </Wrapper.loginNow>
                </Wrapper.link>
            </Wrapper.content>
        </>
    )
}
export default RegisterUser
