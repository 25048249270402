import { themes } from "common/styles/theme";
import styled, { css } from "styled-components";

export const Container = styled.button`
  transition: 0.5s ease all;
  :disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  ${({ type }) => {
    switch (type) {
      case "default":
        return css`
          padding: 5px 20px;
          margin: 0 auto;
          height: 40px;
          background: linear-gradient(180deg, #2196f3 0%, #1d54b0 100%);
          border-radius: 5px;
          text-transform: uppercase;
          color: ${themes.colors.white};
          font-weight: 600;
          border: none;
          @media (max-width: 500px) {
            font-size: 14px;
            height: 30px;
          }
        `;
      case "submit":
        return css`
          width: 70%;
          padding: 10px 60px;
          margin: 0 auto;
          height: 40px;
          background: linear-gradient(180deg, #2196f3 0%, #1d54b0 100%);
          border-radius: 5px;
          text-transform: uppercase;
          color: ${themes.colors.white};
          font-weight: 700;
          border: none;
          @media (max-width: 500px) {
            font-size: 14px;
          }
        `;
      case "buttonBlue":
        return css`
          padding: 5px 20px;
          margin: 0 auto;
          height: 40px;
          background: linear-gradient(180deg, #2196f3 0%, #1d54b0 100%);
          border-radius: 5px;
          text-transform: uppercase;
          color: ${themes.colors.white};
          font-weight: 700;
          border: none;
          @media (max-width: 500px) {
            font-size: 14px;
          }
        `;
      case "normal":
        return css`
          background: linear-gradient(180deg, #2196f3 0%, #1d54b0 100%);
          color: white;
          padding: 4px 10px;
          border-radius: 6px;
          border: none;
        `;
      case "outline":
        return css`
        padding: 5px 20px;
        margin: 0 auto;
        height: 40px;
        background: linear-gradient(180deg, rgba(26, 28, 32, 0.3) 0%, rgba(26, 28, 32, 0.3) 100%);
        border: 1px solid #363A40;
        border-radius: 5px;
        text-transform: uppercase;
        color: ${themes.colors.white};
        font-weight: 600;

        @media (max-width: 500px) {
          font-size: 14px;
          height: 30px;
        }
        `;
      default:
        return "";
    }
  }}
`;
