import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Image from 'assets/svg/logoMain.svg'
import Button from 'components/Button'
import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { LoginResgister, OpenOverlay } from 'redux/slices/global'
import { useTranslate } from 'translate'

import { listHeader, listHeaderUser } from './Data'
import {
    ActiveMenu,
    IconMenu,
    Item,
    Logo,
    Menu,
    MenuChildren,
    MenuMoblie,
    MenuProfile,
    Nav,
    NavMoblie,
    NavMoblieItem,
    Span,
    StyledButton,
    StyledButtonMoblie,
    StyledButtons,
    StyledHeader,
    SubHeader
} from './Header.styled'
import MenuCoin from './MenuCoin'
import ProfileDrop from './MenuProfile'
import Notify from './Notify'
import PriceBRX from './PriceBRX'

function Header() {
    const { getUserStore } = useSelector((state) => state.auth)
    const listMenu = getUserStore ? listHeaderUser : listHeader
    const wrapperRef = useRef()
    const { t } = useTranslate()
    const [menu, setMenu] = useState(false)
    const [transparent, setTransparent] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
  
    const handleMenuMoblie = () => {
        setMenu(!menu)
    }
    useEffect(() => {
        window.addEventListener('scroll', handleUserScrolling)
        return () => {
            window.removeEventListener('scroll', handleUserScrolling)
        }
    }, [transparent])
 
    useEffect(() => {
        let html = document.querySelector("html")
        if (menu) {
            html.style.overflow = "hidden"
        } else {
            html.style.overflow = "auto"
        }
    }, [menu])

    const handleUserScrolling = () => {
        if (wrapperRef && wrapperRef.current) {
            const BOUNDARY_OF_TRANSPARANT_BG = wrapperRef.current.clientHeight
            const scrollPosition = document.documentElement.scrollTop
            if (scrollPosition > BOUNDARY_OF_TRANSPARANT_BG) {
                setTransparent(true)
            } else {
                setTransparent(false)
            }
        }
    }

    const handleOpenLoginResgister = (name) => {
        dispatch(LoginResgister(name))
        dispatch(OpenOverlay(true))
        setMenu(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setMenu(false)
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const isHighlightMenu = (item) => {
        if (item?.chidlren) {
            const isMatch = item.chidlren?.find((item) => location.pathname == item.link)
            if (isMatch) return true
            else return false
        }
    }

    let [megaMenu, setMegaMenu] = useState(false)
    let handleOnMouseEnter = (name) => {
        setMegaMenu(name)
    }
    let handleOnMouseLeave = (name) => {
        setMegaMenu('')
    }
    return (
        <StyledHeader bg={menu ? menu : transparent} ref={wrapperRef}>
            <Container style={{ height: '100%' }}>
                <Nav>
                    <Link to={'/'}>
                        {' '}
                        <Logo src={Image} />
                    </Link>
                    <Menu>
                        {listMenu?.map((e, i) => {
                            return (
                                <Span
                                    key={i + 20}
                                    bg={menu ? menu : transparent}
                                    onMouseLeave={() => handleOnMouseLeave(e?.title)}
                                    onMouseEnter={() => handleOnMouseEnter(e?.title)}>
                                    {!e?.type ? (
                                            <NavLink to={e?.link}>
                                                {' '}
                                                <img
                                                    src={e?.icon}
                                                    alt=''
                                                    style={{  maxWidth:'22px' }}
                                                />{' '}
                                                {t(`${e.title}`)}{' '}
                                            </NavLink>
                                    ) : (
                                        <div style={{ position: 'relative', display:'flex', alignItems:'center' }} key={i + 1}>
                                            <img
                                                src={e?.icon}
                                                alt=''
                                                style={{   maxWidth:'22px', marginRight:'5px'}}
                                            />
                                            <ActiveMenu active={isHighlightMenu(e)}>
                                                {' '}
                                                {t(`${e?.title}`)}
                                            </ActiveMenu>
                                            <ArrowDropDownIcon />
                                            {megaMenu == e?.title && (
                                                <MenuChildren>
                                                    {e?.chidlren?.map((e, i) => {
                                                        return (
                                                            <NavLink
                                                                to={e?.link}
                                                                key={i + 10}>
                                                                {t(`${e.title}`)}{' '}
                                                            </NavLink>
                                                        )
                                                    })}
                                                </MenuChildren>
                                            )}
                                        </div>
                                    )}{' '}
                                </Span>
                            )
                        })}
                    </Menu>
                    <StyledButton>
                        {!getUserStore ? (
                            <StyledButtons>
                                <Button
                                    style={{ width: '118px', fontSize: '14px' }}
                                    onClick={() => handleOpenLoginResgister('register')}>
                                    Register
                                </Button>
                                <Button
                                    onClick={() => handleOpenLoginResgister('login')}
                                    style={{
                                        width: '118px',
                                        fontSize: '14px',
                                        marginLeft: '5px'
                                    }}
                                    type='outline'>
                                    Login
                                </Button>
                            </StyledButtons>
                        ) : (
                            <>
                                <Notify />
                                <MenuProfile onClick={handleClick}>
                                    <img src={`${getUserStore?.avatar}`} alt='' />
                                    <ArrowDropDownIcon />
                                </MenuProfile>
                            </>
                        )}
                        {anchorEl && (
                            <ProfileDrop
                                handleClose={handleClose}
                                anchorEl={anchorEl}
                                profile={getUserStore}
                            />
                        )}
                        <MenuMoblie onClick={handleMenuMoblie}>
                            <IconMenu style={{ color: 'white' }} />
                        </MenuMoblie>
                    </StyledButton>
                </Nav>
                <NavMoblie
                    menu={menu && 'active'}
                    getUserStore={getUserStore ? true : false}>
                    <NavMoblieItem>
                        {listMenu.map((e, i) => (
                            <React.Fragment key={i + 9}>
                                {!e?.type && (
                                    <Item>
                                        <NavLink
                                            to={e?.link}
                                            onClick={() => setMenu(!menu)}>
                                            {' '}
                                            {t(`${e?.title}`)}
                                        </NavLink>
                                    </Item>
                                )}
                                {e?.chidlren?.map((e, i) => (
                                    <Item key={i + 10}>
                                        <NavLink
                                            to={e?.link}
                                            onClick={() => setMenu(!menu)}>
                                            {' '}
                                            {t(`${e?.title}`)}
                                        </NavLink>
                                    </Item>
                                ))}
                            </React.Fragment>
                        ))}
                        {!getUserStore && (
                            <StyledButtonMoblie>
                                <Button
                                    style={{ width: '118px', fontSize: '14px' }}
                                    onClick={() => handleOpenLoginResgister('register')}>
                                    Register
                                </Button>
                                <Button
                                    onClick={() => handleOpenLoginResgister('login')}
                                    style={{
                                        width: '118px',
                                        fontSize: '14px',
                                        marginLeft: '5px'
                                    }}
                                    type='outline'>
                                    Login
                                </Button>
                            </StyledButtonMoblie>
                        )}
                    </NavMoblieItem>
                </NavMoblie>
            </Container>
            <div style={{ background: 'black' }}>
                <Container>
                    <SubHeader>
                    <PriceBRX />
                        {getUserStore && (
                            <div
                                style={{
                                    width: '1px',
                                    height: '18px',
                                    margin: '0 10px',
                                    background: '#EAEAEA'
                                }}></div>
                        )}
                        {getUserStore && <MenuCoin />}
                    </SubHeader>
                </Container>
            </div>
        </StyledHeader>
    )
}

export default Header
