import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { MessageToastError, MessageToastSuccess } from 'components/Message'
import { SetUser } from 'utits/auth'

const initialState = {
    getLoading: false,
    userInfo: []
}


export const handleKyc = (data, user) => {
    return async (dispatch) => {
        let dataUser = JSON.parse(JSON.stringify(user))
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/kyc1', data, true)
            MessageToastSuccess(res?.data?.message)
            dataUser.kyc1.status = 'pending'
            SetUser({ ...dataUser }, dispatch)
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}

const packageSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },

        GetUserInfo(state, action) {
            return {
                ...state,
                userInfo: action.payload
            }
        }
    }
})

const { reducer, actions } = packageSlice
export const { GetLoading, GetUserInfo } = actions
export default reducer
