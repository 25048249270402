import imgSuccess from 'assets/img/success.png'
import SVG from 'components/SVG'
import { toast } from 'react-toastify'

import styled from 'styled-components'
import Swal from 'sweetalert2'

const Svg = styled.div`
    display: flex;
    align-items: center;
    > img {
        width: 25px;
        margin-right: 5px;
    }
`

export const MessageToastSuccess = (des) => {
    toast(
        <Svg>
            <img src={imgSuccess} />
            {des}
        </Svg>
    )
}

export const MessageToastError = (des) => {
    toast.error(
        <Svg>
            <SVG name='error' />
            {des}
        </Svg>
    )
}

export const SuccessMessage = (title) => {
    Swal.fire({
        title: title,
        icon: 'success',
        timer: 1500
    })
}

export const ErrorMessage = (title) => {
    Swal.fire({
        title: title,
        icon: 'error',
        timer: 1500
    })
}

export const Enable2FA = (title) => {
    Swal.fire({
        title: title,
        text: 'Please enable 2FA to use this function!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Enable'
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/profile'
        }
    })
}

// export const MiningMessage = (title) => {
//     Swal.fire({
//         title: title,
//         icon: 'success',
//         color: '#ffffff',
//         background: 'linear-gradient(to right,#067780,#02a878 )',

//         timer: 3000
//     })
// }
export const MiningMessage = (title) => {
    Swal.fire({
        title: title,
        icon: 'success',
        color: '#ffffff',
        background: 'linear-gradient(to right,#067780,#02a878 )',
        backdrop: `
            rgba(0, 0, 123, 0.4)
            url("/images/bg-mined.gif")
            
        `,
        timer: 5000
    })
}








