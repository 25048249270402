import { createAction, createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import axios from 'axios'
import { port } from 'port'
import { Navigate } from 'react-router-dom'
import {
    ClearUserSession,
    GetUser,
    SetUser,
    clearSession,
    clearUser,
    setSession
} from 'utits/auth'

import { MessageToastError, MessageToastSuccess } from '../../../components/Message'
import { OpenOverlay } from '../global'

const initialState = {
    openDrawer: false,
    overlay: false,
    getCountry: [],
    getDocument: [],
    getLoading: false,
    getLoadingOTP: false,
    getUserStore: JSON.parse(localStorage.getItem('user')) || '',
    getProfile: '',
    getStatusKyc: '',
    value2fa: false,
    get2fa: '',
    getLevel: ''
}

export const revertAll = createAction('REVERT_ALL')
export const HandleOpenDrawer = (payload) => {
    return async (dispatch) => {
        dispatch(OpenDrawer(payload))
    }
}

export const handleLogout = () => {
    return async (dispatch) => {
        dispatch(SetLoading(true))
        const user = GetUser()
        try {
            let res = await CreateApi.CreateApiLangNoToken(
                '/api/user/v1/log-out',
                {
                    _id: user?._id
                },
                'en'
            )
            dispatch(revertAll())
            ClearUserSession(dispatch)
            MessageToastSuccess(res?.data?.message)
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoading(false))
    }
}
export const HandleVerifyProfile = (data, setStep) => {
    return async (dispatch) => {
        dispatch(SetLoading(true))
        try {
            let res = await CreateApi.CreateApiS(
                'public/user/resend-verify-mail',
                {
                    ...data
                },
                true
            )

            if (res?.data?.status === true) {
                setStep(2)
            } else if (res?.data?.status === false) {
                dispatch(SetLoading(false))
                MessageToastError(res?.data?.message)
            }
            dispatch(SetLoading(false))
        } catch (error) {}
    }
}
export const HandleActiveCode = (data) => {
    return async (dispatch) => {
        dispatch(SetLoading(true))
        try {
            let res = await CreateApi.CreateApi1(
                'public/user/active-verify',
                {
                    ...data
                },
                true
            )

            if (res?.data?.status === true) {
                MessageToastSuccess('Successfully!')
                dispatch(handleGetProfile())
                
            } else if (res?.data?.status === false) {
                dispatch(SetLoading(false))
                MessageToastError(res?.data?.message)
            }
            dispatch(SetLoading(false))
        } catch (error) {
            dispatch(SetLoading(false))
        }
    }
}

export const HandleUpdateProfile = (data) => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true))
            let res = await CreateApi.CreateApi1(
                '/api/user/v1/update-profile',
                data,
                true
            )
            MessageToastSuccess(res?.data?.message)
            dispatch(handleGetProfile())
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoading(false))
    }
}

export const HandleChangePass = (data, reset) => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true))
            let res = await CreateApi.CreateApiLang(
                '/api/user/v1/change-password',
                {
                    ...data
                },
                true
            )
            if (res?.data?.status == 200) {
                MessageToastSuccess(res?.data?.message)
                reset()
            } else if (res?.data?.status == 400) MessageToastError(res?.message)
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoading(false))
    }
}

export const HandleForgotSignUp = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true))
            let res = await CreateApi.CreateApiSS(
                '/api/user/v1/forgot-password',
                {
                    ...data
                },
                true
            )
            if (res?.data?.status == 200) {
                setSession(res?.data?.data?.token)
                MessageToastSuccess(res?.data?.message)
            } else if (res?.data?.status == 400) MessageToastError(res?.message)
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoading(false))
    }
}

export const HandleForgotSendOtp = (data) => {
    return async (dispatch) => {
        dispatch(SetLoadingOTP(true))
        try {
            let res = await CreateApi.CreateApiSS(
                '/api/user/v1/forgot-password',
                {
                    ...data
                },
                true
            )
            if (res?.data?.status == 200) {
                MessageToastSuccess(res?.data?.message)
            }
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoadingOTP(false))
    }
}

export const HandleSubmitSendOtp = (data) => {
    return async (dispatch) => {
        dispatch(SetLoadingOTP(true))
        try {
            let res = await CreateApi.CreateApiSS(
                '/api/user/v1/register',
                {
                    ...data
                },
                true
            )
            if (res?.data?.status == 200) {
                MessageToastSuccess(res?.data?.message)
            }
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoadingOTP(false))
    }
}

export const HandleSubmitSignUp = (data, navigate) => {
    return async (dispatch) => {
        dispatch(SetLoading(true))
        try {
            let res = await CreateApi.CreateApiSS(
                '/api/user/v1/register',
                {
                    ...data
                },
                true
            )
            setSession(res?.data?.data?.token)
            MessageToastSuccess(res?.data?.message)
            dispatch(OpenOverlay(false))
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(SetLoading(false))
    }
}

export const HandleSubmitSignUpAuto = (data, navigate) => {
    return async (dispatch) => {
        dispatch(SetLoading(true))
        if (data.type == 'left') {
            data.left = 'left'
            data.right = ''
            delete data.type
        } else {
            data.right = 'right'
            data.left = ''
            delete data.type
        }
        let res = await CreateApi.CreateApiSS(
            'public/account/signupAuto',
            {
                ...data
            },
            true
        )

        if (res?.data?.status == true) {
            MessageToastSuccess('Sign Up Success')
            setTimeout(() => {
                navigate(`/auth/await`)
            }, 1000)
        } else if (res?.data?.status == false) MessageToastError(res?.data?.message)
        dispatch(SetLoading(false))
    }
}

export const handleVerifyEmail = (value, navigate) => {
    return async (dispatch) => {
        const data = new FormData()
        data.append('activation_code', value.get('code'))
        data.append('token', value.get('token'))

        const res = await axios({
            url: `${port}public/account/verify`,
            method: `POST`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        })
        if (res?.data?.status == true) {
            MessageToastSuccess('Success')
            setSession(res?.data?.data.sessionID)

            let user = await CreateApi.CreateApiSSS('public/user/userinfo', true)
            SetUser(user?.data?.data, dispatch)
            dispatch(GetUserStore(user?.data?.data))

            setTimeout(() => {
                navigate('/')
            }, 1000)
        } else {
            MessageToastError(res.data.message)
        }

        // dispatch(SetLoading(false))
    }
}

export const handleGetCountry = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSSS('/api/public/v1/country', true)
        dispatch(GetCountry(res?.data?.data))
    }
}
export const handleGetDocument = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiDocument('/api/public/v1/document', true)
        dispatch(GetDocument(res?.data?.data))
    }
}

export const handleGetProfile = () => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS('/api/user/v1/get-profile')
            SetUser(res?.data?.data,dispatch)
        } catch (err) {
            if (err.response.status === 401) {
                ClearUserSession(dispatch)
            }
        }
    }
}

export const handleGetStatusKyc = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiPOST('public/user_kyc/info', true)
        dispatch(GetStatusKyc(res?.data))
    }
}
export const handleVerifyForgotPassword = (data) => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSS(
            'public/account/password/forgot/generate',
            data,
            true
        )
    }
}

export const handleGet2FA = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSSS('public/mfa/code', true)
        dispatch(Get2FA(res?.data?.data))
    }
}

export const handleGetLevel = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSSS('public/level/user', true)

        dispatch(GetLevel(res?.data))
    }
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        OpenDrawer: (state, action) => {
            return {
                ...state,
                openDrawer: action.payload
            }
        },

        Overlay: (state, action) => {
            return {
                ...state,
                overlay: action.payload
            }
        },
        SetLoadingOTP: (state, action) => {
            return {
                ...state,
                getLoadingOTP: action.payload
            }
        },
        SetLoading: (state, action) => {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        GetCountry: (state, action) => {
            return {
                ...state,
                getCountry: action.payload
            }
        },
        GetDocument: (state, action) => {
            return {
                ...state,
                getDocument: action.payload
            }
        },
        GetProfile: (state, action) => {
            return {
                ...state,
                getProfile: action.payload
            }
        },

        GetUserStore: (state, action) => {
            return {
                ...state,
                getUserStore: action.payload
            }
        },
        GetStatusKyc: (state, action) => {
            return {
                ...state,
                getStatusKyc: action.payload
            }
        },
        Get2FA: (state, action) => {
            return {
                ...state,
                get2fa: action.payload
            }
        },
        GetValue2fa: (state, action) => {
            return {
                ...state,
                value2fa: action.payload
            }
        },
        GetLevel: (state, action) => {
            return {
                ...state,
                getLevel: action.payload
            }
        }
    }
})

const { reducer, actions } = authSlice
export const {
    OpenDrawer,
    Overlay,
    SetLoading,
    SetLoadingOTP,
    GetCountry,
    GetDocument,
    GetUserStore,
    GetStatusKyc,
    Get2FA,
    GetProfile,
    GetValue2fa,
    GetLevel
} = actions
export default reducer
