import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.div `
  background: #05142E;
  height: 100%;
  width: 100%;
  padding: 50px 0;
`;
export const ContainerStyled = styled(Container) `
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
`;
export const Warraper = styled.div `
  display: flex;
  align-items: start;
  @media(max-width:600px){
    flex-direction: column;
    gap:30px;
  }
`;
export const WarraperAddress = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  > img {
    max-width: 118px;
    width: 100%;
  }
`;

export const WarraperLink = styled.div `
  flex: 2;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  @media (max-width: 600px) {
    align-items: center;
    gap: 25px;
    width:100%;
  }
`;

export const Address = styled.div `
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Context = styled.div `
  color: white;
  > span {
    font-weight: 700;
    font-size: 18px;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
`;
export const Link = styled.div `
  color: white;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
  @media (max-width: 991px) {
    text-align: center;
  }
`;

export const LinkImg = styled.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  gap: 20px;
  > img {
    cursor: pointer;
    max-width: 38px;
    width: 100%;
  }
  @media (max-width: 991px) {
    text-align: center;
    gap: 5px;
  }
`;

export const WarraperTitle = styled.div `
  text-align: center;
  color: white;
  margin-bottom: 50px;

  > p {
    margin: 0;
  }
  > h2 {
    font-weight: 700;
  }
`;