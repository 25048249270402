import { GetUserStore } from 'redux/slices/auth'

const SESSION_KEY = 'sessions'
const PAYMENT_KEY = 'payments'
let USER_KEY = `user`

export const getSession = () => {
    try {
        return JSON.parse(localStorage.getItem(SESSION_KEY))
    } catch (error) {
        return ''
    }
}
export const setSession = (data) => {
    localStorage.setItem(SESSION_KEY, JSON.stringify(data))
}

export const clearSession = () => {
    localStorage.removeItem(SESSION_KEY)
    localStorage.removeItem('Match')
}
export const clearUser = () => {
    localStorage.removeItem(USER_KEY)
}

export const GetUser = () => {
    try {
        return JSON.parse(localStorage.getItem(USER_KEY))
    } catch (error) {
        return ''
    }
}

export const SetUser = (data, dispatch) => {
    dispatch(GetUserStore(data))
    localStorage.setItem(USER_KEY, JSON.stringify(data))
}

export const ClearUserSession = (dispatch) => {
    dispatch(GetUserStore(''))
    localStorage.removeItem(USER_KEY)
    localStorage.removeItem(SESSION_KEY)
    localStorage.removeItem(PAYMENT_KEY)
    localStorage.removeItem('setNoti')
    localStorage.removeItem('timeout')
    localStorage.removeItem('icons')
    localStorage.removeItem('Match')
}

export const getColor = (rating, index) => {
    if (rating > index) {
        return '#FFD705'
    }
    return '#DCDCDC'
}
