import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import {
    ErrorMessage,
    MessageToastError,
    MessageToastSuccess,
    SuccessMessage
} from 'components/Message'
import { DataPriceCoin } from 'core/Coin'
import { PushArray } from 'core/PushArray'
import { PlusWalletUsers,DeductUserWallet } from 'core/WalletUser'
import { SetUser } from 'utits/auth'

const initialState = {
    getLoading: false,
    getLoadingHistory: false,
    inputWithdraw: '',
    getWallet: [],
    getWalletGame:[],
    getReward: '',
    getDeposit: '',
    getHistoryDeposit: '',
    getHistoryTranfer: [],
    getHistoryWithdraw: [],
    getHistoryClaim: [],
    getHistoryReward: [],
    getFee: 0,
    getContess: [],
    getRewardGBRX:[]
}

export const handleGetContest = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiGET('/api/public/v1/contess', true)
        dispatch(GetContess(res?.data?.data))
    }
}

export const handleGetFee = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiGET('/api/user/v1/wallet/fee', true)
        dispatch(GetFee(res?.data?.data))
    }
}

export const handleGetWallet = () => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiDocument('/api/user/v1/wallet/get-wallet')
            dispatch(GetWallet(res?.data?.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const handleGetWalletGame = () => {
    return async (dispatch,getState) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/game/receive-balance-GBRX')
         
            if (getState()?.wallet?.getWallet) {
                const dataWallet = DeductUserWallet(
                    getState()?.wallet?.getWallet,
                    'GBRX',
                    res?.data?.data?.total
                )
                dispatch(GetWallet(dataWallet))
                let Active = JSON.parse(JSON.stringify(getState()?.wallet?.getRewardGBRX))
                Active.isActive = false
                dispatch(GetRewardGBRX(Active))
            }
            MessageToastSuccess(`You have successfully received ${ res?.data?.data?.total} GBRX`)
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}


export const handleGetWalletGBRX = () => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/game/get-balance-GBRX`
            )
        
            dispatch(GetRewardGBRX(res?.data?.data))
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}
export const handleCreateWallet = (name,network) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/deposit?symbol=${name.symbol}&network=${network}`
            )
            dispatch(GetDeposit(res?.data?.data))
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
            dispatch(GetDeposit())
        }
        dispatch(GetLoading(false))
    }
}

export const handleTranfer = (data) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS('public/exchange/claim', data, true)
            if (res?.data?.status === true) {
                SuccessMessage('Tranfer success')
                dispatch(handleHistoryTranfer())
            } else {
                ErrorMessage(res?.data?.message)
            }
        } catch (error) {
            ErrorMessage(error.response?.data?.message)
        }

        dispatch(GetLoading(false))
    }
}

export const handleTranferUser = (getUserStore, data, history, listHistory) => {
    return async (dispatch, getState) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApi1(
                '/api/user/v1/wallet/withdraw-username',
                data,
                true
            )
            const amountWallet = PlusWalletUsers(
                getState()?.wallet?.getWallet,
                data?.symbol,
                Number(data?.amount))
            dispatch(GetWallet(amountWallet))
            MessageToastSuccess(res?.data?.message)
            const pushDataHistory = PushArray(history, listHistory?.array)
            const dataHistory = {
                array: pushDataHistory?.array,
                total: listHistory?.total + 1
            }
            dispatch(GetHistoryTranfer({ data: dataHistory }))
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}

export const handleWithdraw = (data, listHistory, getUserStore) => {
    return async (dispatch, getState) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApi1(
                '/api/user/v1/wallet/withdraw',
                data,
                true
            )
            let FeeRefund = Number(
                getState()?.wallet?.getFee?.FEE_WALLET - getUserStore?.refundWithFraw
            )
              
            const dataWallet = PlusWalletUsers(
                getState()?.wallet?.getWallet,
                data?.symbol,
                Number(data?.amount) + (Number(data?.amount) * FeeRefund) / 100
            )
            dispatch(GetWallet(dataWallet))
            MessageToastSuccess(res?.data?.message)
            const pushDataHistory = PushArray(res?.data?.data, listHistory?.array)
            const dataHistory = {
                array: pushDataHistory?.array,
                total: listHistory?.total + 1
            }
            let datas = JSON.parse(JSON.stringify(getUserStore))
            const MaxWithdraw =
                Number(
                    data?.amount *
                        DataPriceCoin(getState()?.socket?.getPrice, data?.symbol)?.price
                ) / DataPriceCoin(getState()?.socket?.getPrice, 'BUSD')?.price
            datas.amountWithDraw = datas.amountWithDraw + MaxWithdraw
            datas.maxWithdraw =    datas.maxWithdraw - MaxWithdraw
            SetUser(datas, dispatch)
            dispatch(GetHistoryWithdraw({ data: dataHistory }))
        } catch (error) {
            MessageToastError(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}

export const handleHistoryDeposit = (limit) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))
        try {
            let res = await CreateApi.CreateApiSSS(`/api/user/v1/game/top?limit=`)
            dispatch(GetHistoryDeposit(res?.data))
        } catch (error) {}
        dispatch(GetLoadingHistory(false))
    }
}

export const handleHistoryDepositS = (page) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))
        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=${5}&skip=${page}&ref=deposit`,
                true
            )
            dispatch(GetHistoryDeposit(res?.data))
        } catch (error) {}
        dispatch(GetLoadingHistory(false))
    }
}

export const handleHistoryTranfer = (page) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))
        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=&skip=${page}&ref=transfers`,
                true
            )
            dispatch(GetHistoryTranfer(res?.data))
        } catch (error) {}
        dispatch(GetLoadingHistory(false))
    }
}

export const handleHistoryReward = (page) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))
        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=&skip=${page}&ref=bonus_rfg`,
                true
            )
            dispatch(GetHistoryReward(res?.data?.data))
        } catch (error) {}
        dispatch(GetLoadingHistory(false))
    }
}

export const handleHistoryWithdraw = (page) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))

        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=${5}&skip=${page}&ref=withdraw`,
                true
            )
            dispatch(GetHistoryWithdraw(res?.data))
        } catch (error) {}

        dispatch(GetLoadingHistory(false))
    }
}
export const handleHistoryClaim = (page) => {
    return async (dispatch) => {
        dispatch(GetLoadingHistory(true))

        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=&skip=${page}&ref=swap`,
                true
            )
            dispatch(GetHistoryClaim(res?.data))
        } catch (error) {}

        dispatch(GetLoadingHistory(false))
    }
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        GetLoadingHistory(state, action) {
            return {
                ...state,
                getLoadingHistory: action.payload
            }
        },
        InputWithdraw(state, action) {
            return {
                ...state,
                inputWithdraw: action.payload
            }
        },
        GetWallet(state, action) {
            return {
                ...state,
                getWallet: action.payload
            }
        },

        GetReward(state, action) {
            return {
                ...state,
                getReward: action.payload
            }
        },
        GetRewardGBRX(state, action) {
            return {
                ...state,
                getRewardGBRX: action.payload
            }
        },
        GetDeposit(state, action) {
            return {
                ...state,
                getDeposit: action.payload
            }
        },
        GetHistoryDeposit(state, action) {
            return {
                ...state,
                getHistoryDeposit: action.payload
            }
        },
        GetHistoryTranfer(state, action) {
            return {
                ...state,
                getHistoryTranfer: action.payload
            }
        },
        GetHistoryWithdraw(state, action) {
            return {
                ...state,
                getHistoryWithdraw: action.payload
            }
        },
        GetHistoryClaim(state, action) {
            return {
                ...state,
                getHistoryClaim: action.payload
            }
        },
        GetHistoryReward(state, action) {
            return {
                ...state,
                getHistoryReward: action.payload
            }
        },

        GetFee(state, action) {
            return {
                ...state,
                getFee: action.payload
            }
        },
        GetContess(state, action) {
            return {
                ...state,
                getContess: action.payload
            }
        }, 
         GetWalletGame(state, action) {
            return {
                ...state,
                getWalletGame: action.payload
            }
        }

    }
})

const { reducer, actions } = walletSlice
export const {
    GetHistoryReward,
    GetLoading,
    InputWithdraw,
    GetWallet,
    GetReward,
    GetDeposit,
    GetRewardGBRX,
    GetHistoryDeposit,
    GetHistoryTranfer,
    GetHistoryWithdraw,
    GetHistoryClaim,
    GetLoadingHistory,
    GetFee,
    GetContess,
    GetWalletGame
} = actions
export default reducer
