export const theme = {
    primary: "#011358",
    linear: "linear-gradient(90deg, #fea73b 0%, #fb7700 104.55%)",
    secondary: "#0b2072",
    quaternary: "#02273b",
    white: "#ffffff",
    border: "rgba(255, 255, 255, 0.15)",
    text: "#536981",
    red: "red",
    gray: "#81858c",
    blue: "#2589F5",
    bg_gray: "#F4F7F8",
};