import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { ErrorMessage, SuccessMessage } from 'components/Message'
import { DeductUserWallet } from 'core/WalletUser'

import { GetWallet } from '../wallet'

const initialState = {
    loadding: -1,
    listReward: [],
    listBonus:[]
}

export const handleSubmitCommission = (data, index) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(index))

        try {
            let res = await CreateApi.CreateApi1(`/api/user/v1/receive-rewards`, data)
            const dataWallet = DeductUserWallet(
                getState()?.wallet?.getWallet,
                'BUSD',
                res?.data?.data?.amount
            )
            dispatch(GetWallet(dataWallet))

            SuccessMessage(res?.data?.message)
        } catch (error) {
            ErrorMessage(error?.response?.data?.message)
        }
        dispatch(Loadding(-1))
    }
}

export const handleSubmitSales = (data) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data?._id))

        try {
            let res = await CreateApi.CreateApi1(`/api/user/v1/receive-sales`, data)
            const dataWallet = DeductUserWallet(
                getState()?.wallet?.getWallet,
                'BUSD',
                res?.data?.data?.amount
            )

            dispatch(GetWallet(dataWallet))
            const NewList = JSON.parse(JSON.stringify(getState()?.reward?.listReward))
            NewList.filter((e) => e?._id == data?._id)[0].isReceive = true
            dispatch(ListReward(NewList))
            SuccessMessage(res?.data?.message)

        } catch (error) {
            console.log(error);
            ErrorMessage(error?.response?.data?.message)
        }
        dispatch(Loadding(-1))
    }
}
export const handleSubmitBonus = (data) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data?.i))
        try {
            let res = await CreateApi.CreateApi1(`/api/user/v1/receive-5f1first`, data)
            const dataWallet = DeductUserWallet(
                getState()?.wallet?.getWallet,
                'BUSD',
                res?.data?.data?.amount
            )

            dispatch(GetWallet(dataWallet))
            const NewList = JSON.parse(JSON.stringify(getState()?.reward?.listBonus))
            NewList.data.filter((e) => e?.i == data?.i)[0].isReceive = true
            dispatch(ListBonus(NewList))
            SuccessMessage(res?.data?.message)

        } catch (error) {
            ErrorMessage(error?.response?.data?.message)
        }
        dispatch(Loadding(-1))
    }
}
export const handleGetListSales = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSSS(`/api/user/v1/sales`)
        dispatch(ListReward(res?.data?.data))
    }
}
export const handleGetListBonus = () => {
    return async (dispatch) => {
        let res = await CreateApi.CreateApiSSS(`/api/user/v1/5f1first`)
        dispatch(ListBonus(res?.data?.data))
    }
}

const RewardSlice = createSlice({
    name: 'reward',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },
        ListReward: (state, action) => {
            return {
                ...state,
                listReward: action.payload
            }
        },
        ListBonus: (state, action) => {
            return {
                ...state,
                listBonus: action.payload
            }
        }
    }
})

const { reducer, actions } = RewardSlice
export const { Loadding, ListReward,ListBonus } = actions
export default reducer
