import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'

const initialState = {
    loadding: false,
    historyStaking: [],
    historyPrivateSale:[]
}

export const handleGetHistoryStaking = () => {
    return async (dispatch) => {
        dispatch(Loadding(true))

        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=&skip=1&ref=staking`,
                true
            )
            dispatch(HistoryStaking(res?.data?.data))
        } catch (error) {
        }
        dispatch(Loadding(false))

    }
}
export const handleGetHistoryPrivateSale = () => {
    return async (dispatch) => {
        dispatch(Loadding(true))

        try {
            let res = await CreateApi.CreateApiSSS(
                `/api/user/v1/wallet/get-transaction?limit=&skip=1&ref=privateSale`,
                true
            )
            dispatch(HistoryPrivateSale(res?.data?.data))
        } catch (error) {
        }
        dispatch(Loadding(false))

    }
}

const miningSlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },

        HistoryStaking: (state, action) => {
            return {
                ...state,
                historyStaking: action.payload
            }
        },
        HistoryPrivateSale: (state, action) => {
            return {
                ...state,
                historyPrivateSale: action.payload
            }
        }
    }
})

const { reducer, actions } = miningSlice
export const { Loadding, HistoryStaking ,HistoryPrivateSale} = actions
export default reducer
