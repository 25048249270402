import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleGetWalletGame, handleGetWalletGBRX } from 'redux/slices/wallet'
import Game from 'assets/img/iconHeader/Game.png'
import { Item,  ItemMain, SystemMain } from './styles'
import Button from 'components/Button'

export default function Reward() {
    const dispatch = useDispatch()
    const {getLoading,getRewardGBRX} = useSelector((state) =>  state.wallet)
  useEffect(() => {
    dispatch(handleGetWalletGBRX())
  },[])
    const handleGetGBRX = () => {
        dispatch(handleGetWalletGame())
    }
    return (
        <>
          
                <SystemMain>
           
                   
                                    <ItemMain
                                   >
                                    <Item>
                                        <img
                                            src={Game}
                                            alt=''
                                            style={{ width: '25px' }}
                                        />
                                        <p>Get 100 GBRX</p>
                                        <Button style={{height:'35px'}} onClick={() => handleGetGBRX()} disabled={!getRewardGBRX?.isActive}>{getLoading ?  <i className='fa fa-refresh fa-spin'></i> : "Get"}</Button>
                                    </Item>
                                  
                                </ItemMain>
                </SystemMain>
            {/* {getHistoryReward?.total > 14 && (
                <PaginationCustom
                    total={getHistoryReward.total}
                    onChange={(e) => handleChangePage(e)}
                    pageSize={14}
                    currentPage={page}
                    containerStyle={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                />
            )} */}
        </>
    )
}
