import { themes } from 'common/styles/theme'
import styled, { css } from 'styled-components'


export const MenuNotify = styled.div`
    display: flex;
    background-color: #000d29;
   
`
export const MenuNotifyItem = styled.div`
        padding:5px;
        color:white;
        flex:1;
        text-align:center;
        cursor:pointer;
        ${({ active }) =>
        active &&
        css`
        background-image: linear-gradient(to top,rgb(236 3 3 / 15%),rgb(255 106 0 / 0%) 50%);
            border-bottom:2px solid #d90000;
        `}
         
`

