import Aos from 'aos'
import 'aos/dist/aos.css'
import Loadding from 'components/Loadding'
import { GlobalStyle } from 'components/styles/GlobalStyle'
import { theme } from 'components/styles/Theme'
import { Suspense } from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from 'redux/stores'
import { ThemeProvider } from 'styled-components'

import './App.css'
import Routes from './core/RouterConfig'
import TranslateProvider from './translate'

function App() {
    useEffect(() => {
        Aos.init({ duration: 500, delay: 300, startEvent: 'load' })
        Aos.refresh()
    }, [])
   
  
    return (
        <TranslateProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Provider store={store}>
                    <BrowserRouter>
                        <Suspense fallback={<Loadding />}>
                            <Routes />
                        </Suspense>{' '}
                    </BrowserRouter>{' '}
                </Provider>{' '}
            </ThemeProvider>{' '}
        </TranslateProvider>
    )
}

export default App
