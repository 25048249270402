import axios from 'axios'
import { port } from 'port'
import {   getSession } from 'utits/auth'

const API = axios.create()

API.interceptors.response.use((res) => {
    return res
})

export const req12Character = (data) => API.post('api/characters/get12Characters', data)
export const req12CharacterTurn = (data) => {
    API.post('api/characters/turn12Characters', data)
}

export default {
    CreateApiS: (url, data, session) => {
        if (session) {
            session = getSession()
        }

        return API(`${port}${url} `, {
            headers: {
                sessionID: `${session} `,
                'Content-Type': 'multipart/form-data'
            },

            method: 'POST',
            data
        })
    },
    CreateApi1: (url, data) => {
        const token = JSON.parse(localStorage.getItem('sessions'))
        return API(`${port}${url} `, {
            headers: {
                Authorization: 'Bearer ' + token,
            },

            method: 'POST',
            data
        })
    },
    CreateApiSS: (url, data) => {
        return API(`${port}${url} `, {
            method: 'POST',
            data
        })
    },

    CreateApiSSS: (url) => {
        const token = JSON.parse(localStorage.getItem('sessions'))
        return API(`${port}${url}`, {
            headers: {
                Authorization: 'Bearer ' + token
            },

            method: 'GET'
        })
    },
  

    CreateApiDocument: (url) => {
        const token = JSON.parse(localStorage.getItem('sessions'))
        return API(`${port}${url}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                lang: 'en'
            },
            method: 'GET'
        })
    },

    CreateApiGetProfile: (url, token) => {
        return API(`${port}${url}`, {
            headers: {
                Authorization: 'Bearer ' + token
            },

            method: 'GET'
        })
    },

    CreateApiPOST: (url, session) => {
        if (session) {
            session = getSession()
        }
        return API(`${port}${url} `, {
            headers: {
                Authorization: 'Bearer ' + session
            },
            method: 'POST'
        })
    },
    CreateApiGET: (url) => {
        return API(`${port}${url} `, {
            method: 'GET'
        })
    },
    CreateApiLang: (url, data) => {
        const token = JSON.parse(localStorage.getItem('sessions'))
        return API(`${port}${url} `, {
            headers: {
                Authorization: 'Bearer ' + token,
                lang: 'en'
            },

            method: 'POST',
            data
        })
    },
    CreateApiLangNoToken: (url, data, lang) => {
        return API(`${port}${url} `, {
            headers: {
                lang: lang
            },
            method: 'POST',
            data
        })
    }
}
