import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { fixedNumber } from 'components/FormatNumber'
import { ErrorMessage, SuccessMessage } from 'components/Message'
import { PushArray } from 'core/PushArray'
import { DeductUserWallet, DeductUserWalletHarvest, PlusWalletUsers } from 'core/WalletUser'
import { formatMoney } from 'core/truncate'
import moment from 'moment'
import { port } from 'port'
import Swal from 'sweetalert2'

import { OpenOverlay } from '../global'
import { GetWallet } from '../wallet'

const initialState = {
    loadding: false,
    getListFarming: JSON.parse(localStorage.getItem('dataFarm')) || []
}

export const handleGetListFarming = (url, type) => {
    return async (dispatch) => {
        try {
            dispatch(Loadding({ type: 'getlist', _id: 0 }))
            let res = await CreateApi.CreateApiSSS(url, true)
            dispatch(GetListFarming(res?.data?.data))
        } catch (error) {
            console.log(error)
        }
        dispatch(Loadding(false))
    }
}
export const handleDepositFarming = (data) => {
    return async (dispatch, getState) => {
        dispatch(Loadding({ type: 'Deposit', _id: data._id }))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/farming/deposit', data)
            SuccessMessage(res?.data?.message)
            const Filter = JSON.parse(JSON.stringify(getState().farming.getListFarming))
            const Amount = Filter.filter((e) => e._id == data?._id)[0].availableLP
            const AmountPool = Filter.filter((e) => e._id == data?._id)[0].pool

            Filter.filter((e) => e._id == data?._id)[0].availableLP =
                Amount + Number(data?.amount)
            Filter.filter((e) => e._id == data?._id)[0].pool =
                AmountPool + Number(data?.amount)
            dispatch(GetListFarming(Filter))
            const dataWallet = PlusWalletUsers(
                getState()?.wallet?.getWallet,
                getState()?.global?.coinPayment?.symbol,
                Number(data?.amount)
            )
            dispatch(GetWallet(dataWallet))
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }
        dispatch(Loadding(false))
    }
}
export const handleShrunkFarming = (data, fee) => {
    return async (dispatch, getState) => {
        dispatch(Loadding({ type: 'Shrunk', _id: data._id }))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/farming/withdraw', data)
            SuccessMessage(res?.data?.message)
            const Filter = JSON.parse(JSON.stringify(getState().farming.getListFarming))
            const Amount = Filter.filter((e) => e._id == data?._id)[0].availableLP
            const AmountPool = Filter.filter((e) => e._id == data?._id)[0].pool

            Filter.filter((e) => e._id == data?._id)[0].availableLP =
                Amount - Number(data?.amount)
            Filter.filter((e) => e._id == data?._id)[0].pool =
                AmountPool - Number(data?.amount)
            dispatch(GetListFarming(Filter))
            const dataWallet = DeductUserWallet(
                getState()?.wallet?.getWallet,
                getState()?.global?.coinPayment?.symbol,
                Number(fee)
            )
            dispatch(GetWallet(dataWallet))
            dispatch(OpenOverlay(false))
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }
        dispatch(Loadding(false))
    }
}
export const ShowPopupFarming = (dispatch, id, type) => {
    Swal.fire({
        title: `Submit the amount you want to ${type}`,
        input: 'text',

        showCancelButton: true,
        confirmButtonText: 'Send',
        showLoaderOnConfirm: true,

        preConfirm: (login) => {
            const data = {
                _id: id,
                amount: login
            }

            if (login > 0) {
                dispatch(handleDepositFarming(data))
            } else {
                Swal.showValidationMessage(`Request failed`)
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}
export const handleEnableFarming = (data, periodDay) => {
    return async (dispatch, getState) => {
        dispatch(Loadding({ type: 'Enable', _id: data._id }))
        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/farming/activated', data)
            SuccessMessage(res?.data?.message)
            // const Filter = JSON.parse(JSON.stringify(getState().farming.getListFarming))
            // Filter.filter((e) => e._id == data?._id)[0].endDay  =  moment(new Date(Date.now()))
            // .add(periodDay, 'd')
            // .format('MMM DD, YYYY HH:mm:ss')
            // dispatch(GetListFarming(Filter))
            dispatch(handleGetListFarming('/api/user/v1/farming/get'))
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }
        dispatch(Loadding(false))
    }
}

export const hanleHarvestFarming = (data, symbol, coinRecharge, availableLP) => {
    return async (dispatch, getState) => {
        try {
            console.log(symbol)
            let res = await CreateApi.CreateApi1('/api/user/v1/farming/receive', data)
            SuccessMessage(res?.data?.message)
            const Filter = JSON.parse(JSON.stringify(getState().farming.getListFarming))
            let dataWallet
            let Dates =
                moment(Filter.filter((e) => e._id == data?._id)[0].endDay) <
                moment(new Date(Date.now()))
            if (Dates) {
                dispatch(handleGetListFarming('/api/user/v1/farming/get'))
                dataWallet = DeductUserWalletHarvest(
                    getState()?.wallet?.getWallet,
                    symbol,
                    Filter.filter((e) => e._id == data?._id)[0].earmed,
                    coinRecharge,
                    availableLP
                )
            } else {
                dataWallet = DeductUserWallet(
                    getState()?.wallet?.getWallet,
                    symbol,
                    Filter.filter((e) => e._id == data?._id)[0].earmed
                )
            }


            dispatch(GetWallet(dataWallet))

            Filter.filter((e) => e._id == data?._id)[0].earmed = 0
            dispatch(GetListFarming(Filter))
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }
    }
}
export const ChangeDataStaking = (id, arrayList, data) => {
    let result = JSON.parse(JSON.stringify(arrayList))
    result.filter((item) => item?._id === id)[0].isBuy = data?.isBuy
    result.filter((item) => item?._id === id)[0].staking = data?.staking
    result.filter((item) => item?._id === id)[0].createBuy = data?.createBuy
    return result
}

const farmingSlice = createSlice({
    name: 'farming',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },
        GetListFarming: (state, action) => {
            localStorage.setItem('dataFarm', JSON.stringify(action.payload))

            return {
                ...state,
                getListFarming: action.payload
            }
        }
    }
})

const { reducer, actions } = farmingSlice
export const { Loadding, GetListFarming } = actions
export default reducer
