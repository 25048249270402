import React from "react";

export const HomeRouter = (props) => {
    const { Element, meta, ...restProps } = props;
    //propsRoute props.history,props.match
    React.useEffect(() => {
        const title = meta.title || "Warbeast";
        document.title = title;
        window.scrollTo(0, 0);
    }, [meta]);

    return <Element {...restProps }
    />;
};