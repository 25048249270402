import Tele from 'assets/img/Home/tele.svg'
import Tw from 'assets/img/Home/tw.svg'
import ytb from 'assets/img/Home/ytb.svg'
import fb from 'assets/img/Home/fb.svg'
import ins from 'assets/img/Home/ins.svg'
import Binance from 'assets/img/Home/Binance.png'
import pancake from 'assets/img/Home/pancake.png'






export const ListAboutUs = [{
        text: "Terms & Conditions",
    },
    {
        text: "Privacy Policy",
    },
    {
        text: "Cookie Policy",
    },
    {
        text: "Responsible Disclosure",
    },

];
export const Privacy = [{
        text: "Careers",
    },
    {
        text: "Press",
    },
    {
        text: "Help",
    },
    {
        text: "Contact",
    },
];
export const What = [{
    text: "Home",
},
{
    text: "What is BRX?",
},
{
    text: "Whitepaper",
    link:"https://docs.bridex.org/",
},
{
    text: "BRX Coin",
},
];
export const Partners = [
    {
        icon: Binance,
        link:'https://www.binance.com/vi'
    },
    {
        icon: pancake,
        link:'https://pancakeswap.finance/'
    },
];

export const MXH = [
    {
    icon: Tele,
    link:'https://t.me/BridexBRX'
},
{
    icon: Tw,
    link:'#'
},
{
    icon: ytb,
    link:'#'
},
{
    icon: fb,
    link:'#'
},
{
    icon: ins,
    link:'#'
},
];