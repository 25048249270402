import Home from 'assets/img/iconHeader/home.png'
import Private from 'assets/img/iconHeader/PrivateSale.png'
import Exchange from 'assets/img/iconHeader/exchange.png'
import Staking from 'assets/img/iconHeader/Staking.png'
import Game from 'assets/img/iconHeader/Game.png'
import Farming from 'assets/img/iconHeader/farming.png'
import Wallet from 'assets/img/iconHeader/Wallet.png'








export const listHeader = [{
        title: "Home",
        link: "/",
        icon: Home,
    },
    {
        title: "Private Sale",
        link: "/private-sale",
        icon: Private,
        type: false,
    },
    {
        title: "Exchange",
        link: "/exchange",
        icon: Exchange,
        type: false,
    },
    {
        title: "Staking",
        link: "/staking",
        icon: Staking,
        type: false,
    },
    {
        title: "Farming",
        link: "/farming",
        icon: Farming,
        type: false,
    },


];

export const listHeaderUser = [{
        title: "Home",
        link: "/",
        icon: Home,
    },
    {
        title: "Private Sale",
        link: "/private-sale",
        icon: Private,
        type: true,
        chidlren: [{
                title: "Private Sale",
                link: "/private-sale",
            },
            {
                title: "History",
                link: "/history-private-sale",
            },


        ]
    },
    {
        title: "Exchange",
        link: "/exchange",
        icon: Exchange,
        type: false,
    },
    {
        title: "Staking",
        icon: Staking,
        type: true,
        chidlren: [{
                title: "Staking",
                link: "/staking",
            },
            {
                title: "History",
                link: "/history-staking",
            },


        ]
    },
    {
        title: "Wallet",
        icon: Wallet,
        type: true,

        chidlren: [{
                title: "Deposit",
                link: "/deposit",
            },
            {
                title: "Withdraw",
                link: "/withdraw",
            },
            {
                title: "Transfer",
                link: "/transfer",
            }

        ]
    },

    {
        title: "Farming",
        link: "/farming",
        icon: Farming,
        type: false,
    },
    {
        title: "Game",
        link: "/play-game",
        icon: Game,
        type: false,
    },



];