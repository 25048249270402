import Header from 'components/Header/index.js'
import Footer from 'components/Footer'

import ShadowLayer from 'components/ShadowLayer/index.jsx'
import Login from 'page/auth/Login/index.jsx'
import Register from 'page/auth/Register/index.jsx'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { actionChangeDevice } from 'redux/slices/global'

import { Container, Content } from './UserLayout.css.jsx'

export default function UserLayout() {
    const dispatch = useDispatch()
    const { loginResgister } = useSelector((state) => state.global)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) dispatch(actionChangeDevice(true))
            if (window.innerWidth > 992) dispatch(actionChangeDevice(false))
        }

        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])
    
    return (
        <Container>
            <Content>
                <Header  />
                <Outlet />
                {loginResgister == 'login' && <Login />}
                {loginResgister == 'register' && <Register />}
                <ShadowLayer />
                <Footer />
            </Content>
        </Container>
    )
}
