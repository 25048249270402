import { yupResolver } from '@hookform/resolvers/yup'
import CreateApi from 'api/CreateApi'
import Logo from 'assets/svg/logo.svg'
import Button from 'components/Button'
import RecaptchaF2A from 'components/RecaptchaF2A/RecaptchaF2A'
import SVG from 'components/SVG'
import { LOGIN_FORM_FIELD, schemeLogin } from 'core/customValidate'
import { port, portRegister } from 'port'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    NavLink,
    useLocation,
    useNavigate,
    useSearchParams
} from 'react-router-dom'
import { LoginResgister, OpenOverlay } from 'redux/slices/global'
import { IconNotify } from 'redux/slices/socket'
import {  GetUser, clearUser, getSession, setSession } from 'utits/auth'

import {
    ErrorMessage,
    MessageToastSuccess,
} from '../../../components/Message'
import { Overlay, revertAll, SetLoading } from '../../../redux/slices/auth'
import { Wrapper } from './styles'

const Login = () => {
    const { getLoading } = useSelector((state) => state.auth)
    const [check2fa, setCheck2fa] = useState(false)
    const session = getSession()
    const [state, setState] = useState(session)
    const [searchParams] = useSearchParams()
    const { search } = useLocation()
    const { isOverlay } = useSelector((state) => state.global)
    useEffect(() => {
        if (search) {
            setSession(searchParams.get('token'))
            setState(searchParams.get('token'))
        }
    }, [search])

    useEffect(() => {
        if (!isOverlay) {
            dispatch(LoginResgister(''))
        }
    }, [isOverlay])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = GetUser()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schemeLogin),
        mode: 'all'
    })
    useEffect(() => {
        if (user) {
            clearUser()
        }
    }, [user])
 
    const handleCheck2FA = async () => {
        dispatch(SetLoading(true))

        let res = await CreateApi.CreateApiSS(
            'public/account/verify2FA',
            // {
            //   email: valueInput.email,
            //   password: valueInput.password,
            //   token: value2fa,
            // },
            true
        )
        const data = res?.data?.data

        if (res?.data?.status == true) {
            MessageToastSuccess('Logged in successfully')
            setSession(data.sessionID)
            dispatch(Overlay(''))
            // navigate('/')
        } else if (res?.data?.status == false) {
            ErrorMessage(res.data.message)
        }

        dispatch(SetLoading(false))
    }

    const submit = async (dataForm) => {
        dispatch(SetLoading(true))
        try {
            let res = await CreateApi.CreateApiLangNoToken(
                '/api/user/v1/login',
                dataForm,
                'en'
            )
            // dispatch(revertAll())
            const data = res?.data?.data
            dispatch(OpenOverlay(false))
            setSession(data?.token)
            MessageToastSuccess(res?.data?.message)
            reset()
            // navigate('/')
            dispatch(IconNotify(1))
        } catch (error) {
            if (error?.response?.status == 405) {
                setCheck2fa(true)
            } else {
                ErrorMessage(error.response.data.message)
            }
        }
        // }
        dispatch(SetLoading(false))
    }



const hadleSignUp = () => {
    dispatch(LoginResgister('register'))
 
}

    return (
        <>
            <Wrapper.main
                onSubmit={check2fa ? handleSubmit(handleCheck2FA) : handleSubmit(submit)}>
                <Wrapper.title style={{borderBottom:'1px solid #C9C8C8'}}>
                    <h2>Sign in</h2>
                </Wrapper.title>
                <Wrapper.title>
                    <img src={Logo} alt='' />
                    {/* <h2>Sign in</h2> */}
                </Wrapper.title>
                <Wrapper.context>
                    <Wrapper.inputWrapper>
                        <Wrapper.input
                            type='text'
                            placeholder='Email'
                            error={errors[LOGIN_FORM_FIELD.EMAIL] === undefined}
                            {...register('email', {
                                required: true
                            })}
                        />
                        <p className='message-error'>
                            {errors[LOGIN_FORM_FIELD.EMAIL]?.message}
                        </p>
                    </Wrapper.inputWrapper>
                    <Wrapper.inputWrapper>
                        <Wrapper.input
                            type='password'
                            placeholder='Password'
                            error={errors[LOGIN_FORM_FIELD.PASSWORD] === undefined}
                            {...register('password', {
                                required: true
                            })}
                        />
                        <p className='message-error'>
                            {errors[LOGIN_FORM_FIELD.PASSWORD]?.message}
                        </p>
                    </Wrapper.inputWrapper>
                    {/* <Recaptcha /> */}
                    {check2fa && <RecaptchaF2A />}

                    <Button type='submit' buttonStyle={{ padding: '5px 20px' }}>
                        {getLoading ? <i className='fa fa-refresh fa-spin'></i> : 'Login'}
                    </Button>

                    <Wrapper.link>
                        <NavLink to='/forgot-password'>
                            <p>Forgot password?</p>
                        </NavLink>
                        <div style={{ display: 'flex' }}>
                            <span>
                                If you do not already have an account ?
                                <b
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>hadleSignUp()}>
                                    {' '}
                                    Sign up.
                                </b>
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Or Sign In With</span>
                            <div>
                                {/* <a href={`${port}/auth/google`}> */}
                                <a href={`${port}/auth/google`}>

                                    <SVG
                                        name='logo_google'
                                        style={{ marginTop: '15px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </Wrapper.link>
                </Wrapper.context>
            </Wrapper.main>
        </>
    )
}
export default Login
