import DraftsIcon from '@mui/icons-material/Drafts'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconNotify } from 'redux/slices/socket'

import { Item, ItemDes, ItemMain, SystemMain } from './styles'

export default function System({ limit, setLimit, loading, setLoading }) {
    const dispatch = useDispatch()
    const { notify } = useSelector((state) => state.socket)
    const [idx, setIdx] = useState(-1)
    const handleChange = (e) => {
        if (idx != e?._id) {
            setIdx(e?._id)
        } else {
            setIdx(-1)
        }
    }

    const handleLoading = () => {
        setLoading(true)
        dispatch(IconNotify(0))
        setTimeout(() => {
            setLimit(limit + 10)
            setLoading(false)
        },1500)
    }
    
    return (
        <SystemMain>
            {notify?.data?.map((e, i) => {
                if(i<=limit)
                    return (
                        <ItemMain
                            key={i}
                            active={idx === e?._id}
                            onClick={() => handleChange(e)}>
                            <Item>
                                <DraftsIcon style={{ color: 'white' }} />
                                <p>{e?.name}</p>
                                <div>{moment(new Date(e?.createAt)).fromNow()}</div>
                            </Item>
                            {idx == e?._id && (
                                <ItemDes active={idx === e?._id}>
                                    {e?.description}
                                </ItemDes>
                            )}
                        </ItemMain>
                    )
            })}
            {limit < notify?.total && (
                <>
                    {loading ? (
                        <ItemMain>
                            <Item>
                                <i className='fa fa-refresh fa-spin'></i>
                            </Item>
                        </ItemMain>
                    ) : (
                        <ItemMain onClick={handleLoading}>
                            <Item style={{ fontWeight: 'bold' }}>See More</Item>
                        </ItemMain>
                    )}
                </>
            )}
        </SystemMain>
    )
}
