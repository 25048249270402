import { themes } from 'common/styles/theme'
import styled, { css } from 'styled-components'

export const SystemMain = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000d29;
`
export const ItemMain = styled.div`
    height: auto;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    &:hover {
        cursor: pointer;
        background: linear-gradient(
                0deg,
                rgba(106, 49, 129, 0.2),
                rgba(106, 49, 129, 0.2)
            ),
            linear-gradient(
                115.36deg,
                rgba(255, 148, 0, 0.4) 33.92%,
                rgba(0, 26, 83, 0.4) 99.86%
            );
    }
    ${({ active }) =>
        active &&
        css`
            background: linear-gradient(
                    0deg,
                    rgba(106, 49, 129, 0.2),
                    rgba(106, 49, 129, 0.2)
                ),
                linear-gradient(
                    115.36deg,
                    rgba(255, 148, 0, 0.4) 33.92%,
                    rgba(0, 26, 83, 0.4) 99.86%
                );
        `}
`
export const ItemDes = styled.div`
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0;
    color:white;
    font-size:12px;
    margin-top:5px;
    ${({ active }) =>
        active &&
        css`
            max-height: 200px;
        `}
`
export const Item = styled.div`
    ${themes.mixin.flexCenter()};
    color: white;
    border-radius: 5px;
    gap: 5px;
    width: 100%;

    > p {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-size: 12px;
        flex: 2;
        margin: 0;
    }
    > div {
        font-size: 12px;
    }
`
