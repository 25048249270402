import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { ErrorMessage, SuccessMessage } from 'components/Message'
import { PushArray } from 'core/PushArray'
import { DeductUserWallet, PlusAndMinusWallet, PlusWalletUsers } from 'core/WalletUser'
import { SetUser } from 'utits/auth'

import { GetHistoryClaim, GetWallet } from '../wallet'

const initialState = {
    getLoadding: false,
    getHistoryExchange: []
}

export const handleExchange = (
    data,
    lang,
    getFee,
    history,
    listHistory
) => {
    return async (dispatch, getstate) => {
        dispatch(GetLoading(true))
       
        try {
            let res = await CreateApi.CreateApiLang(
                '/api/user/v1/wallet/swap',
                data,
                lang
            )
            const datas = PlusAndMinusWallet(
                getstate()?.wallet?.getWallet,
                data.symbolFrom,
                data.symbolTo,
                data?.amount,
                history?.amount
            )
            dispatch(GetWallet(datas))

            const pushDataHistory = PushArray(history, listHistory?.array)
            const pushDataHistorys = PushArray(res?.data?.data, pushDataHistory?.array)

            const dataHistory = {
                array: pushDataHistorys?.array,
                total: listHistory?.total + 2
            }
            dispatch(GetHistoryClaim({ data: dataHistory }))
            SuccessMessage('Exchange Success')
        } catch (error) {
            ErrorMessage(error?.response?.data?.message)
        }
        dispatch(GetLoading(false))
    }
}

export const handleHistoryExchange = (page) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))

        try {
            let res = await CreateApi.CreateApiS(
                'public/exchange/history',
                {
                    limit: 5,
                    page: page
                },
                true
            )
            dispatch(GetHistoryExchange(res?.data))
        } catch (error) {}

        dispatch(GetLoading(false))
    }
}
const exchangeSlice = createSlice({
    name: 'exchange',
    initialState,
    reducers: {
        GetLoading: (state, action) => {
            return {
                ...state,
                getLoadding: action.payload
            }
        },
        GetHistoryExchange: (state, action) => {
            return {
                ...state,
                getHistoryExchange: action.payload
            }
        }
    }
})

const { reducer, actions } = exchangeSlice
export const { GetLoading, GetHistoryExchange } = actions
export default reducer
