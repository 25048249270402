import Image from 'assets/svg/logoMain.svg'
import BSON from 'bson'
import { ErrorMessage } from 'components/Message'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { handleGetProfile, handleLogout } from 'redux/slices/auth'
import { GetPrice, handleGetPrice } from 'redux/slices/socket'
import { handleGetFee, handleGetWallet } from 'redux/slices/wallet'
import socket from 'socket'
import { useTranslate } from 'translate'
import { ClearUserSession, GetUser, getSession, setSession } from 'utits/auth'

import { ListAboutUs, ListMxh, MXH, Partners, Privacy, What } from './data'
import {
    Address,
    ContainerStyled,
    Context,
    Link,
    Section,
    Warraper,
    WarraperAddress,
    WarraperLink
} from './footer.styled'

function Footer() {
    const { t } = useTranslate()
    const dispatch = useDispatch()
    const { getWallet, getFee } = useSelector((state) => state.wallet)
    const session = getSession()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    useEffect(() => {
        if(location?.pathname === '/login'){
            setSession(searchParams.get('token'))
            navigate('/')
        }
      
        if (session) {
            dispatch(handleGetProfile())
            if (getWallet?.length == 0) dispatch(handleGetWallet())
            if (!getFee) dispatch(handleGetFee())
        }
       
    }, [session])
    const [active, setActive] = useState(JSON.parse(localStorage.getItem('PP')))
    useEffect(() => {
        if (!active) dispatch(handleGetPrice(setActive))
    }, [])
    // useEffect(() => {
    //     if (session) {
    //         socket.emit('send-connect-login', session)
    //         socket.on('connect-login', (res) => {
    //             if (res?.status == 403) {
    //                 ClearUserSession(dispatch)
    //                 ErrorMessage('Already another account is logging in')
    //                 socket.off('connect-login')
    //                 socket.off('send-connect-login')
    //             }
    //         })
    //     } else {
    //         socket.off('connect-login')
    //         socket.off('send-connect-login')
    //     }
    //     return () => {
    //         socket.off('connect-login')
    //         socket.off('send-connect-login')
    //     }
    // }, [session])
    useEffect(() => {
        socket.on('listCoin', (result) => {
            const res = BSON.deserialize(result)
            dispatch(GetPrice(res?.data))
        })
        return () => {
            socket.off('listCoin')
        }
    }, [])
 
    return (
        <Section>
            <ContainerStyled>
                <Warraper>
                    <WarraperAddress>
                        <img src={Image} alt='' />
                        <Address>
                            <div>
                                <strong>Join the Community</strong>
                            </div>
                            <div>
                                {MXH?.map((e, i) => (
                                    <a
                                        href={e?.link}
                                        target='_blank'
                                        key={i}
                                        style={{ marginRight: '10px' }}>
                                        <img src={e?.icon} />
                                    </a>
                                ))}
                            </div>
                        </Address>
                    </WarraperAddress>
                    <WarraperLink>
                        <Context>
                            <Link>
                                {ListAboutUs?.map((e, i) => (
                                    <span key={i}>{t(`${e?.text}`)}</span>
                                ))}
                            </Link>
                        </Context>
                        <Context>
                            <Link>
                                {Privacy?.map((e, i) => (
                                    <span key={i}>{t(`${e?.text}`)}</span>
                                ))}
                            </Link>
                        </Context>
                        <Context>
                            <Link>
                                {What?.map((e, i) => (
                                    <React.Fragment key={i}>
                                    {e?.link ? <a href={e?.link} target="_blank" key={i} style={{color:"white", cursor:'pointer'}}>{t(`${e?.text}`)}</a> :  <span key={i}>{t(`${e?.text}`)}</span>}
                                    </React.Fragment>
                                ))}
                            </Link>
                        </Context>
                        <Context>
                            <strong style={{ fontSize: '20px' }}> Partner</strong>
                            <Link style={{ flexDirection: 'initial' }}>
                                {Partners?.map((e, i) => (
                                    <a href={e?.link} target='_blank' key={i}>
                                        <img
                                            src={e?.icon}
                                            style={{ width: '40px', marginRight: '5px' }}
                                        />
                                    </a>
                                ))}
                            </Link>
                        </Context>
                    </WarraperLink>
                </Warraper>
            </ContainerStyled>
        </Section>
    )
}

export default Footer
