import MenuIcon from '@mui/icons-material/Menu'
import { themes } from 'common/styles/theme'
import { theme } from 'components/styles/Theme'
import styled from 'styled-components'
import { css } from 'styled-components'

export const StyledHeader = styled.header`
    background: ${(props) => (props.bg == false ? 'none' : ' #05142E')};
    position: ${(props) => (props.bg == false ? 'sticky' : 'sticky')};
    top: 0;
    height: 90px;
    width: 100%;
    z-index: 9;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
`
export const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: white;
`
export const Logo = styled.img`
max-width:100px;
width:100%;
margin-top:-7px;
`

export const Menu = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;
    > a {
        > a {
            display: flex;
            align-items: center;
            gap: 5px;
            color: white;
        }
    }
    @media (max-width: 991px) {
        display: none;
    }
`
export const Span = styled.div`
    color: white;
    cursor: pointer;
    ${themes.mixin.flexCenter()};
    height:100%;
    

    > a {
        color: white;
        cursor: pointer;
        display: flex;
        gap: 5px;
        align-items: center;
        &.active {
            color: ${theme.blue};
            font-weight:bold;
        }
    }
`

//moblie
export const NavMoblie = styled.div`
    position: absolute;
    max-height: ${props => props.getUserStore ? '100vh': '400px'};
    background: #05142e;
    width: 100vw;
    left: 0;
    z-index: 1000;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    height: 0;
 
    ${(props) =>
        props.menu == 'active' &&
        css`
            height: 100vh;
            overflow:auto;
            padding-bottom:50px;
        `}
`
export const MenuMoblie = styled.div`
    display: none;
    @media (max-width: 991px) {
        display: block;
    }
`
export const NavMoblieItem = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-top: 50px;
`
export const Item = styled.li`
    color: black;
    align-items: center;
    box-shadow: rgb(0 0 0 / 30%) 0px 6px 6px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    background: rgba(61, 84, 174, 0.2);
    > a {
        color: white;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
     
       
        &.active {
            color: ${theme.blue};
            font-weight:bold;

        }
    }
`
export const StyledButtonMoblie = styled.div``
export const IconMenu = styled(MenuIcon)`
    color: ${(props) => props.theme.white};
    cursor: pointer;
`

export const StyledButton = styled.div`
    ${themes.mixin.flexCenter()};
    gap: 10px;
`

export const MenuProfile = styled.div`
    cursor: pointer;
    > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`

export const SubHeader = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`

export const MenuChildren = styled.div`
    position: absolute;
    top: 43px;
    background:rgba(0, 13, 41, 0.8);
    padding:10px;
    border-radius: 10px;
    >a{
        color:white;
        &.active{
            color:#2589F5;
            font-weight:bold;
            
        }
    }
`

export const ActiveMenu = styled.div`
color:${(props) => (props.active ? '#2589F5' : 'white')};
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    font-size:14px;
`
export const StyledButtons = styled.div`
display:block;
@media(max-width:991px){
    display:none;
}
`