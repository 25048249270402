import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'antd/dist/reset.css'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            keepPreviousData: true,
            retry: 0,
            refetchOnWindowFocus: false,
            retryDelay: 3000
        }
    }
})
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
    <>
        <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <App />
        </QueryClientProvider>
    </>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
