import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import reward from 'assets/img/iconHeader/reward.png'
import * as React from 'react'
import { useDispatch  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleLogout } from 'redux/slices/auth'
import IconInvest from 'assets/img/invest/icon.png'


export default function ProfileDrop({ handleClose, anchorEl, profile }) {
    const open = Boolean(anchorEl)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleLogoutClick = () => {
        dispatch(handleLogout())
    }
    return (
        <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'auto',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    background: 'rgba(0, 13, 41, 0.8)',
                    color: 'white',
                    pb:1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem sx={{ color: '#FF9400' }}>{profile?.email}</MenuItem>
       

            <Divider />
            <MenuItem onClick={() => navigate('/invest')} style={{color: "#ff9402"}}>
           <img src={IconInvest} alt="" style={{width:'24px', marginRight:'5px'}} />
           Invest
            </MenuItem>
            <MenuItem onClick={() => navigate('/reward')} >
                <img src={reward} alt="" style={{marginRight:'5px'}} />
            Reward
            </MenuItem>
      
            <MenuItem onClick={() => navigate('/referral')}>
            Referral
            </MenuItem>
           
            <MenuItem onClick={() => navigate('/profile')}>
            Profile
            </MenuItem>
            <MenuItem onClick={() => navigate('/change-password')}>
                Change password
            </MenuItem>
            <MenuItem onClick={() => navigate('/kyc')}>KYC</MenuItem>
            <MenuItem onClick={() => handleLogoutClick()}>Logout</MenuItem>
        </Menu>
    )
}
