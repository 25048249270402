import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'
import { fixedNumber } from 'components/FormatNumber'
import { ErrorMessage, SuccessMessage } from 'components/Message'
import { PushArray } from 'core/PushArray'
import { DeductUserWallet, PlusWalletUsers } from 'core/WalletUser'
import { formatMoney } from 'core/truncate'
import moment from 'moment'
import { SetUser } from 'utits/auth'

import { HistoryStaking } from '../history'
import { GetWallet } from '../wallet'

const initialState = {
    loadding: false,
    getListStaking: []
}

export const handleGetListStaking = (url) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS(url)
            dispatch(GetListStaking(res?.data?.data))
        } catch (error) {
            console.log(error)
        }
    }
}

export const ChangeDataStaking = (id, arrayList, data) => {
    let result = JSON.parse(JSON.stringify(arrayList))
    result.filter((item) => item?._id === id)[0].isBuy = data?.isBuy
    result.filter((item) => item?._id === id)[0].staking = data?.staking
    result.filter((item) => item?._id === id)[0].createBuy = data?.createBuy
    return result
}

export const handleBuyStaking = (
    data,
    symbol,
    Prices,
    receive,
    fee,
    getUserStore,
    max
) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data?._id))

        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/staking/buy', data)
            if (getState()?.wallet?.getWallet) {
                const dataWallet = PlusWalletUsers(
                    getState()?.wallet?.getWallet,
                    symbol,
                    Prices
                )
                dispatch(GetWallet(dataWallet))
            }
            const listStaking = ChangeDataStaking(
                data?._id,
                getState()?.staking?.getListStaking,
                res?.data?.data
            )
            dispatch(GetListStaking(listStaking))
            if (getState().history?.historyStaking?.array) {
                const data = {
                    amount: Prices,
                    symbol: symbol,
                    description: `You bought Stake BRX ${receive}  BRX pack for ${formatMoney(
                        String(fixedNumber(Prices, 8))
                    )} ${symbol} ! `,
                    createAt: moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss'),
                    type: 'transfers'
                }
                const pushDataHistory = PushArray(
                    data,
                    getState().history?.historyStaking?.array
                )
                const dataHistory = {
                    array: pushDataHistory?.array,
                    total: getState().history?.historyStaking?.total + 1
                }
                dispatch(HistoryStaking(dataHistory))
            }
            const userProfile = JSON.parse(JSON.stringify(getUserStore))
            if (fee > userProfile.refundWithFraw) userProfile.refundWithFraw = fee
            if (max > userProfile.maxWithdraw) userProfile.maxWithdraw = max
            SetUser(userProfile, dispatch)
            SuccessMessage('Buy Staking Success')
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }

        dispatch(Loadding(false))
    }
}
export const ChangeReceiveStaking = (id, arrayList, month) => {
    let result = JSON.parse(JSON.stringify(arrayList))
    let test = arrayList.filter((item) => item?._id === id)[0].staking
    let test2 = JSON.parse(JSON.stringify(test))
    test2.filter((item) => item.month == month)[0].isReceive = true
    result.filter((item) => item?._id === id)[0].staking = test2
    let test100 = 0
    test?.map((e, i) => {
        if (e.isReceive == true) {
            test100 = test100 + 1
            return test100
        }
    })
    if (test100 == 4) {
        result.filter((item) => item?._id === id)[0].staking = 0
        result.filter((item) => item?._id === id)[0].isBuy = false
    }

    return result
}
export const handleReceive = (data, amount, receive) => {
    return async (dispatch, getState) => {
        dispatch(Loadding(data?.month))

        try {
            let res = await CreateApi.CreateApi1('/api/user/v1/staking/receive', data)
            if (getState()?.wallet?.getWallet) {
                const dataWallet = DeductUserWallet(
                    getState()?.wallet?.getWallet,
                    'BRX',
                    amount
                )
                dispatch(GetWallet(dataWallet))
            }
            const listStaking = ChangeReceiveStaking(
                data?._id,
                getState()?.staking?.getListStaking,
                data?.month
            )
            dispatch(GetListStaking(listStaking))
            if (getState().history?.historyStaking?.array) {
                const data = {
                    amount: amount,
                    symbol: 'BRX',
                    description: `You get ${amount} BRX - March from Stake BRX ${receive} BRX package !`,
                    createAt: moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss'),
                    type: 'receive'
                }
                const pushDataHistory = PushArray(
                    data,
                    getState().history?.historyStaking?.array
                )
                const dataHistory = {
                    array: pushDataHistory?.array,
                    total: getState().history?.historyStaking?.total + 1
                }
                dispatch(HistoryStaking(dataHistory))
            }
            SuccessMessage('Buy Staking Success')
        } catch (error) {
            ErrorMessage(error.response.data.message)
        }

        dispatch(Loadding(false))
    }
}
const stakingSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        Loadding: (state, action) => {
            return {
                ...state,
                loadding: action.payload
            }
        },
        GetListStaking: (state, action) => {
            return {
                ...state,
                getListStaking: action.payload
            }
        }
    }
})

const { reducer, actions } = stakingSlice
export const { Loadding, GetListStaking } = actions
export default reducer
