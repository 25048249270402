import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import BUSD from 'assets/img/coin/BUSD.png'
import Game from 'assets/img/iconHeader/Game.png'
import { fixedNumber, formatMoney } from 'components/FormatNumber'
import { filterWallet } from 'core/WalletUser'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CoinPayment } from 'redux/slices/global'
import { handleGetWalletGame } from 'redux/slices/wallet'

export default function MenuLang() {
    const dispatch = useDispatch()
    const { getWallet } = useSelector((state) => state.wallet)
    const { coinPayment } = useSelector((state) => state.global)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const location = useLocation()
    React.useEffect(() => {
        if (location?.pathname != '/play-game' && coinPayment?.symbol == "GBRX" ) {
            dispatch(CoinPayment(getWallet[0]))
        } else if(location?.pathname == '/play-game' && (coinPayment?.symbol == "ID" ||  coinPayment?.symbol == "CFX" ||  coinPayment?.symbol == "MATIC" || coinPayment?.symbol == "OP" || coinPayment?.symbol == "OGN" || coinPayment?.symbol == "EDU" )){
            dispatch(CoinPayment(getWallet[0]))
        }
    }, [location])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    function ChangeLang(data) {
        dispatch(CoinPayment(data))
        handleClose()
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<img src={coinPayment?.image} style={{ width: '20px' }} />}
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                    color: 'white',
                    padding: '0 10px',
                    fontSize: '14px'
                }}>
                {filterWallet(coinPayment?.symbol, getWallet)?.price
                    ? formatMoney(
                          String(
                              fixedNumber(
                                  filterWallet(coinPayment?.symbol, getWallet)?.price,
                                  4
                              )
                          )
                      )
                    : 0.0}{' '}
                {/* {coinPayment?.symbol} */}
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}>
                {getWallet?.map((e, i) => {
                    if( location?.pathname != '/play-game'){
                        if(e?.symbol != 'GBRX')
                        return (
                            <MenuItem onClick={() => ChangeLang(e)} key={i + 1}>
                                <img
                                    src={e?.image}
                                    style={{ width: '20px', marginRight: '10px' }}
                                />{' '}
                                {e?.symbol}
                            </MenuItem>
                        )
                    }else{
                        if(e?.symbol != 'OP' && e?.symbol != 'ID' && e?.symbol != 'MATIC'  && e?.symbol != 'EDU' && e?.symbol != 'CFX' && e?.symbol != 'OGN')
                        return (
                            <MenuItem onClick={() => ChangeLang(e)} key={i + 1}>
                                <img
                                    src={e?.image}
                                    style={{ width: '20px', marginRight: '10px' }}
                                />{' '}
                                {e?.symbol}
                            </MenuItem>
                        )
                    }
                   
                })}
                {/* {location?.pathname == '/play-game' && (
                    <MenuItem onClick={() => ChangeLang({ image: Game, symbol: 'GBRX' })}>
                        <img src={Game} style={{ width: '20px', marginRight: '10px' }} />{' '}
                        GBRX
                    </MenuItem>
                )} */}
            </Menu>
        </div>
    )
}
