/* eslint-disable react/jsx-pascal-case */
import RegisterUser from "./User";
import { Wrapper } from "./styles";
import { Navigate } from "react-router-dom";
import { GetUser } from "utits/auth";
import Logo from 'assets/svg/logo.svg'
import { LoginResgister } from "redux/slices/global";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const Register = () => {
  const { isOverlay } = useSelector((state) => state.global)
  const dispatch = useDispatch()

  const user = GetUser();

  useEffect(() => {
    if (!isOverlay) {
        dispatch(LoginResgister(''))
    }
}, [isOverlay])

  return (
    <>
  
          <Wrapper.main>
          <Wrapper.title style={{borderBottom:'1px solid rgb(201, 200, 200)'}}>
              <h2>Register</h2>
            </Wrapper.title>
            <Wrapper.title>
              <img src={Logo} alt="" />
              <h2 style={{fontSize:'24px'}}>Create your Bridex account</h2>
            </Wrapper.title>
            <RegisterUser />
          </Wrapper.main>
    
    </>
  );
};
export default Register;
