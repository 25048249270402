import { themes } from 'common/styles/theme'
import styled from 'styled-components'

export const Wrapper = {

    main: styled.div`
        background: white;
        max-width: 494px;
        width:95%;
        @media (max-width: 768px) {
            margin: 50px auto;
        }
        border-radius: 20px;
        font-size: 16px;
        position: relative;
        z-index: 99999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 768px) {
            top: 40%;
            max-height:475px;
            height: 100%;
            overflow: auto;
        }
    `,
    content: styled.div`
        padding: 30px 20px 30px 20px;
        ${themes.mixin.flexDirectionNotCenter()};
        gap: 20px;
        > button {
            height: 45px;
            background: linear-gradient(to right, #7c0d6a, #ea0274);
            border-radius: 5px;
            text-transform: uppercase;
            color: ${themes.colors.black};
            font-weight: 500;
        }
    `,
    title: styled.div`
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        // background: ${themes.colors.black};
        border-radius: 20px 20px 0px 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px 20px 0px 0px;
        color: ${themes.colors.black};
        > img {
            margin-bottom: 20px;
            width: 100%;
            max-width:190px;
        }
        > h2 {
            color: ${themes.colors.black};
            font-family: 'IBM Plex Sans';
            font-weight: 700;
            font-size: 30px;
            width: 100%;
            text-align: center;
            margin:0;
        }
    `,
    input: styled.div`
        ${themes.mixin.flexDirectionNotCenter()};
        gap: 10px;
        color: ${themes.colors.black};
        > input {
            height: 40px;
            border-radius: 7px;
            color: ${themes.colors.black};
            padding-left: 15px;
        }
    `,
    checkbox: styled.div`
        gap: 10px;
        justify-items: center;
        align-items: center;
        justify-content: center;
        display: flex;
        color: ${themes.colors.black};
        display: flex;
        > input {
            cursor: pointer;
            height: 40px;
            border-radius: 7px;
            color: ${themes.colors.black};
            padding-left: 15px;
        }
        > span > a {
            color: #e80274;
        }
    `,
    select: styled.div``,
    link: styled.div`
        ${themes.mixin.flexDirectionNotCenter()};
        gap: 5px;
        color: ${themes.colors.black};
    `
}
