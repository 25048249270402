
import { themes } from 'common/styles/theme'
import styled, { css } from 'styled-components'


export const Wrapper = {
  main:styled.div`
  ${themes.mixin.flexCenter()};
  width:100vw;
  height:100vh;
  background-color:white;
  flex-direction:column;
  >h2{
      font-weight:bold;
      @media(max-width:500px){
        font-size:20px;
        text-align: center;
      }
  }
  >p{
      font-weight:bold;
      font-size:18px;
  }
  
  `
    
}






