import { themes } from 'common/styles/theme'
import styled from 'styled-components'

export const Wrapper = {
    content: styled.form`
        padding: 20px 20px 30px 20px;
        ${themes.mixin.flexDirectionNotCenter()};
        gap: 15px;
        @media (max-width: 768px) {
            gap: 10px;
            padding: 5px 5px 20px 5px;
        }
        width: 90%;
        margin: 0 auto;
    `,
    title: styled.div`
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border-radius: 20px 20px 0px 0px;
        > h2 {
            color: ${themes.colors.black};
            font-family: 'IBM Plex Sans';
            font-weight: 700;
            font-size: 1.5em;
            margin-bottom: 0;
            width: 100%;
            text-align: center;
        }
    `,
    inputWrapper: styled.div`
        ${themes.mixin.flexDirectionNotCenter()};
        display: flex;
        flex-direction: column;
        gap: 5px;
        @media (max-width: 500px) {
            font-size: 13px;
        }
        color: ${themes.colors.black};
        > p {
            margin-bottom: 0px;
            font-size: 16px;
            @media (max-width: 500px) {
                font-size: 13px;
            }
        }
        > .message-error {
            @media (max-width: 500px) {
                font-size: 10px;
            }
            font-size: 13px;
            color: red;
        }
    `,
    input: styled.input`
        width: 100%;
        height: 40px;
        border: 1px solid #EAEAEA;
        border-radius: 7px;
        color: ${themes.colors.black};
        padding-left: 15px;
        border-color: ${(props) => (props.error === false ? 'red' : '')};
        outline: none;
    `,
    inputOtp: styled.div`
        ${themes.mixin.flexCenter()};
        gap: 10px;
        border: 1px solid #EAEAEA;
        border-radius: 7px;
        @media (max-width: 500px) {
            font-size: 13px;
            gap: 7px;
        }
        color: ${themes.colors.white};
        > div {
            width: 30%;
            @media (max-width: 600px) {
                width: 30%;
                font-size: 11px;
            }
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 7px;
            font-size: 14px;
            font-weight: 500;
            background: linear-gradient(180deg,#2196f3 0%,#1d54b0 100%);
            color: ${themes.colors.black};
            border-color: transparent;
        }
        > input {
            @media (max-width: 500px) {
                width: 70%;
            }
            border: none;
            width: 82%;
            height: 40px;
            border-radius: 7px;
            color: ${themes.colors.black};
            padding-left: 15px;
            border-color: transparent;
        }
    `,
    checkbox: styled.div`
        gap: 10px;
        justify-items: center;
        align-items: center;
        justify-content: start;
        display: flex;
        color: ${themes.colors.black};
        display: flex;
        font-size: 14px;
        @media (max-width: 500px) {
            font-size: 12px;
        }
        > input {
            cursor: pointer;
            height: 40px;
            border-radius: 7px;
            color: ${themes.colors.black};
            padding-left: 15px;
        }

        > span > a {
            font-weight: 500;
            color: #00a3ff;
        }
    `,
    select: styled.div``,
    link: styled.div`
        ${themes.mixin.flexCenter()};
        gap: 20px;
        color: ${themes.colors.black};
        @media (max-width: 768px) {
            justify-content: center;
        }
        justify-content: space-between;
        align-items: start;
        font-size: 14px;
        flex-wrap: wrap;
    `,
    connect: styled.div`
        ${themes.mixin.flexDirection()};
        gap: 3px;
    `,
    loginNow: styled.div`
        ${themes.mixin.flexDirection()};
        gap:20px;
        > a > b {
            color: #00a3ff;
            margin-top: 6px;
        }
    `
}
