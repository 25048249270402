import styled, { css } from 'styled-components'



export const Container = styled.div`
`

export const Content = styled.div`
    transition: 0.5s;
    background: #0c214c;
    ${({ isMobile }) =>
        isMobile &&
        css`
            width: 100%;
        `}
`



export const MenuItem = styled.div`
    width: ${({ width }) => `${width || 100}%`};
    height: 3px;
    background-color: white;
    border-radius: 3px;
    transition: 0.5s;
`
