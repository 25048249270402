export const PlusWalletUsers = (getWallet, symbol, amount) => {
    let data = JSON.parse(JSON.stringify(getWallet))
    const dataAmount =
        getWallet.filter((e) => e.symbol == symbol)[0].price - Number(amount)
    data.filter((e) => e.symbol == symbol)[0].price = dataAmount

    return data
}

export const ChangeVariable = (list, id, name, isActive) => {
    let data = JSON.parse(JSON.stringify(list))
    const ListItem = JSON.parse(JSON.stringify(list))
    const dataAmount = (ListItem.filter((e) => e._id == id)[0][name] = isActive)
    data.filter((e) => e._id == id)[0][name] = dataAmount

    return data
}

export const DeductUserWalletHarvest = (getWallet, symbol, amount, coinRecharge, amountCoinRecharge) => {
    let data = JSON.parse(JSON.stringify(getWallet))
    const dataAmount =
    getWallet.filter((e) => e.symbol == symbol)[0].price + Number(amount)
    const dataAmountCoinRecharge =
    getWallet.filter((e) => e.symbol == coinRecharge)[0].price + Number(amountCoinRecharge)
    data.filter((e) => e.symbol == symbol)[0].price = dataAmount
    data.filter((e) => e.symbol == coinRecharge)[0].price = dataAmountCoinRecharge
    return data
}

export const DeductUserWallet = (getWallet, symbol, amount) => {
    let data = JSON.parse(JSON.stringify(getWallet))
    const dataAmount =
    getWallet.filter((e) => e.symbol == symbol)[0].price + Number(amount)
    data.filter((e) => e.symbol == symbol)[0].price = dataAmount

    return data
}
export const SetDeductUserWallet = (getUserStore, symbol, amount) => {
    let data = JSON.parse(JSON.stringify(getUserStore))
    let data2 = JSON.parse(JSON.stringify(getUserStore))
    const dataAmount =
        data2.wallet.filter((e) => e.symbol == symbol)[0].myAmount + Number(amount)
    data.wallet.filter((e) => e.symbol == symbol)[0].myAmount = dataAmount

    return data
}

export const PlusAndMinusWallet = (
    getWallet,
    symbolFrom,
    symbolTo,
    amount,
    amountSwap
) => {
    let data = JSON.parse(JSON.stringify(getWallet))
    const Deduction =
        getWallet.filter((e) => e.symbol == symbolFrom)[0].price - Number(amount)
    const PublicMoney =
        getWallet.filter((e) => e.symbol == symbolTo)[0].price + amountSwap

    data.filter((e) => e.symbol == symbolFrom)[0].price = Deduction

    data.filter((e) => e.symbol == symbolTo)[0].price = PublicMoney

    return data
}

export const filterWallet = (name, array) => {
    const result = array?.filter((item) => {
        if (item?.symbol === name) {
            return item
        }
    })

    if (result) return result[0]
    return {}
}


