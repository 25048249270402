export const PushArray = (data, listdata) => {
    const array = []
    array.push(data, ...listdata)
    return { array }
}

export const PushTypeArray = (data, listdata, nameType, valueType) => {
    const array = []
    let datas = { ...data, [nameType]: valueType }
    array.push(datas, ...listdata)
    return { array }
}
export const PushObjArray = (data, listdata, Obj) => {
    const array = []
    let datas = { ...data, ...Obj }
    array.push(datas, ...listdata)
    return array
}
// export const PlusProfileUsers = (getUserStore, profile) => {
//     console.log(getUserStore?.user)
//     let data = getUserStore?.user
//     data.name == profile.name
//     data.phone == profile.phone
//     data.firstName == profile.firstName
//     data.lastName == profile.lastName
//     data.displayName == profile.displayName
//     data.countryId == profile.countryId
//     data.avatar == profile.avatar
//     return data
// }
