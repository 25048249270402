import { createSlice } from '@reduxjs/toolkit'
import CreateApi from 'api/CreateApi'

const initialState = {
    getLoading: false,
    getParent: [],
    getBinaryTree: [],
    getBinaryList: []
}

export const handleGetParent = () => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiSSS('/api/user/v1/tree', true)
            dispatch(GetParent(res?.data))
        } catch (error) {}
        dispatch(GetLoading(false))
    }
}
export const handleGetBinaryTree = () => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiPOST('public/binary/tree', true)
            dispatch(GetBinaryTree(res?.data))
        } catch (error) {}
        dispatch(GetLoading(false))
    }
}

export const handleGetHistoryBinaryList = (page, side) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        const data = {
            limit: 5,
            page: page,
            side: side
        }
        try {
            let res = await CreateApi.CreateApiS('public/binary/list', data, true)
            dispatch(GetBinaryList(res?.data))
        } catch (error) {}
        dispatch(GetLoading(false))
    }
}

const referralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        GetParent(state, action) {
            return {
                ...state,
                getParent: action.payload
            }
        },
        GetBinaryTree(state, action) {
            return {
                ...state,
                getBinaryTree: action.payload
            }
        },
        GetBinaryList(state, action) {
            return {
                ...state,
                getBinaryList: action.payload
            }
        }
    }
})

const { reducer, actions } = referralSlice
export const { GetLoading, GetParent, GetBinaryTree, GetBinaryList } = actions
export default reducer
