import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import socket from 'socket';
import maintenance from 'assets/img/Maintenance.jpeg';
import { Wrapper } from './styles';

export default function Maintenance() {
    const navigate = useNavigate()
    useEffect(() => {
        socket.on('maintenance', (res) => {
            if(res.maintenance == 'off'){
                navigate('/')
            }
        })
        return () => {
            socket.off('maintenance')
        }
    }, [])
  return (
    <Wrapper.main>
        <h2>Website is currently under maintenance</h2>
        <p>Sorry for this problem</p>
        <p>Please come back later</p>
        <img src={maintenance} alt="" />
    </Wrapper.main>
  )
}
