import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    gettokenrep: false,
    getvalue2fa: '',
    getLoading: false,
    get2FA: ''
}

export const handleOn2FA = (data) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiS('public/exchange/claim', data, true)

            MessageToastSuccess(res?.data?.message)
            // dispatch(Get2FA(res?.data))
        } catch (error) {
            ErrorMessage(error.response?.data?.message)
        }

        dispatch(GetLoading(false))
    }
}

export const handleDisable2FA = (data) => {
    return async (dispatch) => {
        dispatch(GetLoading(true))
        try {
            let res = await CreateApi.CreateApiS('public/exchange/claim', data, true)
            MessageToastSuccess(res?.data?.message)
        } catch (error) {
            ErrorMessage(error.response?.data?.message)
        }

        dispatch(GetLoading(false))
    }
}

const exchangeSlice = createSlice({
    name: 'recaptchaF2A',
    initialState,
    reducers: {
        GetLoading(state, action) {
            return {
                ...state,
                getLoading: action.payload
            }
        },
        Get2FA(state, action) {
            return {
                ...state,
                get2FA: action.payload
            }
        },
        GetTokenRep(state, action) {
            return {
                ...state,
                gettokenrep: action.payload
            }
        },
        GetValue2FA(state, action) {
            return {
                ...state,
                getvalue2fa: action.payload
            }
        }
    }
})

const { reducer, actions } = exchangeSlice
export const { GetTokenRep, GetValue2FA, Get2FA, GetLoading } = actions
export default reducer
